
import React,{useState,useEffect,useRef,useContext,useMemo} from 'react';
import { useParams,useLocation } from 'react-router-dom';
import "../style/SlideInMobile.scss";
import './default.css';
import './stripeStyle.css';
import './joinbakesoft1.css';
import '../style/ChristmasTree.css';
import '../style/SmartSearch.css';
import {useGetData1,usePostData1,usePostData,useGetData,usePostData2} from '../hooks/useGetData';
import { RotatingLines } from 'react-loader-spinner'
import { IoIosCloseCircleOutline } from "react-icons/io";
import useReportErr from '../hooks/useReportErr';
import { CiUnlock } from "react-icons/ci";
import { BiHappyBeaming } from "react-icons/bi";
import { IoCloudDoneOutline } from "react-icons/io5";
import AppError from '../functions/AppError';
import Alert from '../hooks/appAlert';
import { SlWallet } from "react-icons/sl";
import { jwtDecode } from 'jwt-decode';
import { MdDoneOutline } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import useFormatViews from '../functions/ViewsCounter';
import LogoutUserOnly from './LogoutUser';
import bslivelogobig1 from '../assets/images/bslivelogobig1.jpg';
import ChristmasTreeIcon from '../functions/ChristmasTree';
import { IoSearchOutline } from "react-icons/io5";
import { LuFileText } from "react-icons/lu";
import { AlarmClock, CodeSquare, Globe, MapPin } from 'lucide-react';
import CookieWarning from '../functions/CookieWarning';
import ScrollLimitMessage from '../functions/ScrollLimitMessage';
import languages from '../functions/AppLanguage';
import LanguageSelector from '../functions/LanguageSelector';
import TermsComponent from '../functions/Terms';
import AppTerms from '../functions/AppTerms';
import PaymentSuccess from '../functions/PaymentSuccess';
import PaymentSuccessSmall from '../functions/PaymentSuccessSmall';
import { Key, DoorOpen, UserCircle,UserCheck, LifeBuoy, Scale,Contact2Icon} from 'lucide-react'
import useTextObfuscator from '../hooks/textObfuscator';
import SmartSearchInput from '../functions/SmartSearch';
import CookingTimer from '../functions/CoockingTimer';
import NutrientPieChart from '../functions/PieChart';
import BakesoftPromotion from '../functions/BakesoftPromotion';
import AnimatedChef from '../functions/AnimatedChef';
import { ChefHat, Star,Languages} from 'lucide-react'
import ChefProfile from '../functions/ChefPresentation';
import { LuCopy } from "react-icons/lu";
import AboutPage from '../functions/AboutPage';




import {
  FaHeart,
  FaShare,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaThumbsUp,
  FaTimes
} from 'react-icons/fa';
import { FiPlusCircle,FiMinusCircle } from "react-icons/fi";

import UnlockButton from './UnlockButton';
import { Heart, Share2, Eye,Clock,Bookmark, Book, CalendarDays } from 'lucide-react'
import { FaPlus,FaMinus } from "react-icons/fa6";
import { IoLogOutOutline } from "react-icons/io5";
import { VscClose } from "react-icons/vsc";


// stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {PaymentElement,useStripe,useElements} from "@stripe/react-stripe-js";



export const MyContext = React.createContext();

export default function Live() {

//const stripePromise = loadStripe("pk_test_51Q2BZPP8AlL8ItFuAKaCpzjZAgZqZyLIQfoHpnSdkoizcEQEVpNJDMbB85fbmCSqgUxfA9Hce7EA4Q1okCmRTZhg00APiWIEEJ");
const stripePromise = loadStripe("pk_live_51Q2BZPP8AlL8ItFuETVcJBoEneSZaLM4l1QGDdr51MBxxAyeiLsSweubonnCbGWXGoZLs7ZmUNqPeIzdZqxmfrQQ00EmBSFMyE");
    
const [getData, setData] = useState([]); // changed to hold the full array
    const [recipeData, setRecipeData] = useState([{}]);
    const [recipeHeaderData, setRecipeHeaderData] = useState([{}]);
    const [getImages, setImages] = useState([]);
    const[getSpinnerLoading,setSpinnerLoading]=useState(false);
    const [liveCreators,setLiveCreators]=useState([{}]);
    const [liveMenu,setLiveMenu]=useState([{}]);
    const [getAlertCode, setAlertCode] = useState(0);
    const [windowWidth, setWindowWith] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [mobileIsUsed, setMobileIsUsed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [ChosenRecipeId, setChosenRecipeId] = useState(0);
    const [CreatorUserid, setCreatorUserid] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [liveCurrencyData, setLiveCurrencyData] = useState([{}]);
    const [liveCountryData, setLiveCountryData] = useState([{}]);
    const [currency, setCurrency] = useState('');
    const [country,setCountry] = useState('');
   // const [CreatorProfileImage, setCreatorProfileImage] = useState('');
    const[showalert,setShowAlert]=useState(false);
    const[getAlertMessage,setAlertMessage]=useState('');
    const[showLogin, setShowLogin] = useState(true);
  const [stripePay, setStripePay] = useState(false);     // inside payment
  const [stripePayOn, setStripePayOn] = useState(false); // sign up payment
  const [isCreateLogin, setIsCreateLogin] = useState(0);
  const [showUnlockButton, setShowUnlockButton] = useState(false);
  
  const [userid, setUserid] = useState(0);
  const[recipePrice,setRecipePrice]=useState(0);
  const [creatorXchangeRate,setCreatorXchangeRate]=useState(0);
  const[priceInLocalCurrency,setPriceInLocalCurrency]=useState(0);
  const[creatorCurrencyShort,setCreatorCurrencyShort]=useState('');
  const[serviceInfo,setServiceInfo]=useState([{}]);
  const[recipeUnlocked,setRecipeUnlocked]=useState(false);
  const[recipeAccess,setRecipeAccess]=useState([{}]);
  const[showLogoutButton,setShowLogoutButton]=useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [loggedOutMessage, setLoggedOutMessage] = useState(false);
  const [userUnlockedRecipes,setUserUnlockedRecipes] = useState([{}]);
  const [creatorIngredients,setCreatorIngredients]=useState([{}]);
  const [recipeSaved, setRecipeSaved] = useState(false);
  const[bakesoftSetup,setBakesoftSetup]=useState(false);
  const[closeJoinBakesoftModal,setCloseJoinBakesoftModal]=useState(false);
  const[showJoinBakesoftAdd,setShowJoinBakesoftAdd]=useState(false);
  
  const [chosenImage, setChosenImage] = useState('');
  const[creditsToDebitCustomer,setCreditsToDebitCustomer]=useState(0);
  const[getPresignedImageUrl,setPresignedImageUrl]=useState('');
  const[nutrientName,setNutrientName]=useState('');
  const[chosenIngredient,setChosenIngredient]=useState(0);
  const[showUpdateButton,setShowUpdateButton]=useState(false);
  const [checked,setChecked] = useState([]);
  //
  const[searchString,setSearchString]=useState('');
  const[userTransactionData,setUserTransactionData]=useState([{}]);
  const[getSizeVal,setSizeVal]=useState(0);
  const [showNutrientModal, setShowNutrientModal] = useState(false);
  const[trigger,setTrigger]=useState(0);
  const[userOwnIngredName,setUserOwnIngredName]=useState('');


  const[languageCountries,setLanguageCountries]=useState([{}]);
  const[getSelectedLanguage,setSelectedLanguage]=useState('');
  const[closeLanguageSelector,setCloseLanguageSelector]=useState(false);
  const[showKitchenTimer,setShowKitchenTimer]=useState(false);
  const[expandedIngredTot,setExpandedIngredTot]=useState(null);
  const[bakesoftPromo1,setBakesoftPromo1]=useState(false);
  const[showForgotModal,setShowForgotModal]=useState(false);

  const[showChefProfile,setShowChefProfile]=useState(false);
  const[chosenChef,setChosenChef]=useState(0);
  const [voteNum, setVotenum] = useState(0);
  const[chefProfileData,setChefProfileData]=useState([{}]);
  const[resetToken,setResetToken]=useState('');

  const[getRecipeLanguage,setRecipeLanguage]=useState('');
  const[recipeLanguageMatch,setRecipeLanguageMatch]=useState(false);
  const[showAboutPage,setShowAboutPage]=useState(false);



// nutrients
const [nutrientData,setNutrientData]=useState([{}]);




// infinite scrolling
const [offset,setOffset]=useState(0);
const [limit,setLimit]=useState(0);
const[limitReached,setLimitReached]=useState(false);
// use refs
const offsetRef = useRef(0); // Ref to track the latest offset

//  smart search
const[chosenSmartSearchRecipeId,setChosenSmartSearchRecipeId]=useState(0);
const[chosenSmartSearchCreatorId,setChosenSmartSearchCreatorId]=useState(0);



const[chosenRecipeName,setChosenRecipeName]=useState('');
const[endDate,setEndDate]=useState('');


  // menu
  const[menuSelection,setMenuSelection]=useState(0);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [showLoginByUserSelectChoice, setShowLoginByUserSelectChoice] = useState(false);
  const[showLogoutByUserSelectChoice,setShowLogoutByUserSelectChoice]=useState(false);
  const[showAccountByUserSelectChoice,setShowAccountByUserSelectChoice]=useState(false);
  const[S5_ServiceInfo,setS5_ServiceInfo]=useState([{}]);
  const[ServiceInfoXchangeRate,setServiceInfoXchangeRate]=useState([{}]);

  // social sharing window
  const [showShareModal, setShowShareModal] = useState(false);
  const [socialSharedData, setSocialSharedData] = useState([{}]);
  const[expandedIngredRow,setExpandedAddingredRow]=useState(null);
  const[showSignUp,setShowSignUp]=useState(false);
  const[seenBySocialMedia,setSeenBySocialMedia]=useState(false);

 // translate
 const[ translateMode,setTranslateMode]=useState(false);
  const [recipeTobeTranslated, setRecipeTobeTranslated] = useState(false);
  const[languageToTranslateTo,setLanguageToTranslateTo]=useState('');
  const[showTerms,setShowTerms]=useState(false);

  // change of language code
  const[updateNewLanguageCodeToUser,setUpdateNewLanguageCodeToUser]=useState(false);


    // stripe
    const [getStripePayment, setStripePayment] = useState(false);
    const [getClientUpdate,setClientUpdate]=useState('');
    const [getTransactionId,setTransactionId]=useState('');
    const [getCustomerId,setCustomerId]=useState('');
    const [getSelectedService,setSelectedService]=useState('s1');
    const [getPassword,setPassword]=useState('');
    const [getConfirmPassword,setConfirmPassword]=useState('');
    const [getCustomerPrice,setCustomerPrice]=useState(0);
    const [getCurrencyName,setCurrencyName]=useState('');
    const [getPaymentModal, setPaymentModal] = useState(false);
    const[clientSecret,setClientSecret]=useState('');
    const [closeCompletePage, setcloseCompletePage] = useState(false);

    // format views
    const { formatViews } = useFormatViews();
    // end format views

    // query in parameters
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const blsrid = queryParams.get('receptid'); // Retrieve the bslrid parameter
    const blsuid = queryParams.get('userid'); // Retrieve the blsuid parameter; // Retrieve the blsimid parameter
    let browserLang = (navigator.language || navigator.userLanguage || "en").substring(0, 2);
    if (browserLang === 'sv') {
      browserLang = 'se'; // Correct the language code for bakesoft system
    } else if (browserLang === 'fil') {
      browserLang = 'ph'; // Ensure 'fil' for Philippines is mapped correctly
    } else if (browserLang === 'es') {
      browserLang = 'es';
    } else if (browserLang === 'en') {
      browserLang = 'en';
    } else if (browserLang === 'pt') {
      browserLang = 'pt';
    } else if (browserLang === 'ja') {
      browserLang = 'ja';
    } else if (browserLang === 'tr') {
      browserLang = 'tr';
    } else if (browserLang === 'kr') {
      browserLang = 'kr';
    } else if (browserLang === 'fr') {
      browserLang = 'fr';
    } else if (browserLang === 'de') {
      browserLang = 'de';
    } else if (browserLang === 'vi') {
      browserLang = 'vi';
    } else if (browserLang === 'it') {
      browserLang = 'it';
    } else if (browserLang === 'no') {
      browserLang = 'no';
    } else if (browserLang === 'fi') {
      browserLang = 'fi';
    } else if (browserLang === 'dk') {
      browserLang = 'dk';
    } else if (browserLang === 'da') {
      browserLang = 'dk';
    } else if (browserLang === 'pl') {
      browserLang = 'pl';
    } else if (browserLang === 'nl') {
      browserLang = 'nl';
    } else if (browserLang === 'el') {
      browserLang = 'el';
    } else if (browserLang === 'se') {
      browserLang = 'se';
    } else if (browserLang === 'zh') {
      browserLang = 'zh';
    } else if (browserLang === 'ph') {
      browserLang = 'ph';
    } else if (browserLang === 'gb') {
      browserLang = 'gb';
    } else if (browserLang === 'ca') {
      browserLang = 'ca';
    } else if (browserLang === 'ko') {
      browserLang = 'kr';
    }else if(browserLang === 'ar'){
      browserLang='ar'
    }else if(browserLang === 'fa'){
      browserLang='fa'
    }else if (browserLang === 'sw') {
      browserLang='sw'
    }
    
    else{
      browserLang='en'
    }
    const[userCountry,setUserCountry]=useState(localStorage.getItem('LoginLiveCountry')||browserLang);

    useEffect(() => {
    if(browserLang||localStorage.getItem('LoginLiveCountry')){
    setRecipeLanguageMatch(true)  // if we have a language match we dont need to show the language selector
    }

  }, [browserLang,localStorage.getItem('LoginLiveCountry')]);


    //!recipeLanguageMatch
    //Språk automatiseringen fungerar så att vi tar browser språket och när vi hämtar 
    // receptet ifrån databasen, om dessa språk matchar så visar vi receptets namn på det språket.
    // I bakesoft.se kan skaparen av receptet, översätta namnet på receptet i olika språk.
    // så det är dessa översättningar vi hämtar om dom finns.
    // Automatiserigen gör också att vi passerar förbi det initiala språkvalet man måste göra
    // när man kommer till applikationen. Detta sparas i localstorage.
    // Vi passerar språkvalet om browser språket matchar något av dom språk som finns
    // i live.bakesoft.se.(16 språk).
    // matchande parametrar är setLanguageCountries(språk ) // språk i databasen
    //                                        (browserLang) // browser språket
    //                                        (getRecipeLanguage) // receptets språk
    //


    const paymentIntent = queryParams.get('payment_intent');  // used to check in the backend
    const returnCode = queryParams.get('returnid'); // what complete page to show
    const ppreceptid=queryParams.get('ppreceptid'); // payment success code
    const ppcreatorid=queryParams.get('ppcreatorid'); // payment success code
    const selectedError=AppError[localStorage.getItem('LoginLiveCountry')||'en']
    

    async function PaymentConfirmByCallback() {
      setSpinnerLoading(true);
   //  let apiKey = process.env.REACT_APP_LIVEKEY;
      let payemail=localStorage.getItem('LoginLiveEmail')
      try{
       // let response=await usePostData1(`addPayedCreditsToCustomer`,{paymentIntent:paymentIntent,email:payemail},apiKey);
        let response=await usePostData(`addPayedCreditsToCustomer`,{paymentIntent:paymentIntent,email:payemail});
       
        console.log(response)
        if(response.status === 200) {
          setSpinnerLoading(false);
          let credits=response.data.returnedCredits
          localStorage.setItem('LoginLiveCredits',credits)
          setcloseCompletePage(true)
      }else if(response.status===299){
        setSpinnerLoading(false);
        setAlertCode(300014)
        setAlertMessage(selectedError[300014])
        setShowAlert(true)
    
      }
    }catch(error){
      setSpinnerLoading(false);
      setAlertCode(300014)
      setAlertMessage(selectedError[300014])
      setShowAlert(true)
    }
    }
    
    useEffect(() => {
      if(paymentIntent) {
      PaymentConfirmByCallback();
      }
    
    }, [paymentIntent]);
    // end stripe payment



async function GetRecipeName(recipeid,userid) {
 // let apiKey = process.env.REACT_APP_LIVEKEY;
 // let response = await useGetData1(`getRecipeName/${recipeid}/${userid}`,{},apiKey);
  let response = await useGetData(`getRecipeName/${recipeid}/${userid}`,{});
 
  if(response.status===200){
    setSeenBySocialMedia(true)
    setChosenRecipeName(response.data[0][0].benaemn)
    handleImageClick (recipeid,userid,0,response.data[0][0].benaemn,0,1) 
  }else if(response.status===401){
    setAlertCode(300004)
    setAlertMessage(selectedError[300004])
    setShowAlert(true)
  }
}


useEffect(() => {
if(ppreceptid&&ppcreatorid){  
GetRecipeName(ppreceptid,ppcreatorid);
}
}, [ppreceptid,ppcreatorid]);


   
const appearance = {
  theme: 'stripe', // You can try 'flat', 'night', 'none' for full custom control
  variables: {
    colorPrimary: '#1a73e8', // A professional blue shade for primary elements
    colorBackground: '#ffffff', // White background for a clean, modern look
    colorText: '#333333', // Dark grey for readable text
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif', // Clean font stack
    fontWeightNormal: '400', // Normal font weight for regular text
    borderRadius: '6px', // Softer border radius for modern input fields
    spacingUnit: '4px', // Consistent padding between elements
  },
  rules: {
    '.Input': {
      backgroundColor: '#f9f9f9', // Light grey for input backgrounds
      color: '#333333', // Dark grey text for better contrast and readability
      borderColor: '#d1d5db', // Subtle grey border for inputs
      borderRadius: '6px', // Matching border radius for inputs
      padding: '12px', // Comfortable padding inside input fields
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', // Light shadow for input depth
    },
    '.Input:focus': {
      borderColor: '#1a73e8', // Focused input border in the primary color
      boxShadow: '0 0 0 3px rgba(26, 115, 232, 0.3)', // Soft blue focus ring
    },
    '.Label': {
      fontWeight: '600', // Make the labels bold for better emphasis
      color: '#1a73e8', // Use the primary blue for labels to match branding
    },
    '.Button': {
      backgroundColor: '#1a73e8', // Primary blue for button backgrounds
      color: '#ffffff', // White text for high contrast on buttons
      fontSize: '16px', // Comfortable button text size
      padding: '12px 16px', // Adequate padding for buttons
      borderRadius: '6px', // Matching border radius for buttons
      border: 'none', // Remove default button borders
      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', // Soft shadow for button depth
      transition: 'background-color 0.3s ease', // Smooth hover transition
    },
    '.Button:hover': {
      backgroundColor: '#155ab5', // Darker blue on hover for visual feedback
    },
    '.Button:disabled': {
      backgroundColor: '#d1d5db', // Light grey for disabled buttons
      color: '#7d7d7d', // Muted text color for disabled buttons
    },
    '.Error': {
      color: '#e63946', // Bright red for error messages
    },
  },
};



    // language settings
  //  const userCountry = localStorage.getItem('LoginLiveCountry')||'en';
    const Terms = AppTerms[userCountry];
    const selectedCountry = languages[userCountry];


const{
  headerGreeting,
  selectMenu,
  inputTitleBookmark,
  imageTitleChef,
  translateRecipeTextGrid,
  titleShareOnSocialMedia,
  textMinutesInShort,
  titleTextLikeRecipe,
  titleSeenByVievers,
noDescriptionText,
scrollMessageText,
scrollMessageText2,
translateRecipeText,
translateCostText,
selectLanguageText,
noNameAvailableText,
savedRecipeText,
clickToUnlockText,
ingredientText,
pricePerKgText,
priceText,
amountText,
kaloriesText,
increaseIngredientValueText,
decreaseIngredientValueText,
changeNameText,
saveText,
savedText,
deleteText,
totalText,
addIngredientText,
addText,
placeHolderIngredientNameText,
descriptionText,
unlockThisRecipeText,
creditsToUseText,
creditsText,
noCreditsText,
addMoreCreditsText,
gotoPaymentText,
chefInFocusText,
bsLivePaymentText,
bsLiveAmountText,
bsLiveCurrencyText,
bsLivePaymentTermsText,
paymentSuccessfullText,
thankYouForPurchaseText,
selectCurrencyText,
selectLanguageText2,
placeHolderEmailText,
placeHolderPasswordText,
loginText2,
logOutText,
norecipeAvailableText,
notAvailableText,
nameText,
countryText,
currencyText,
noTransactionsFoundText,
paySuccessText,
yourAccountSetupDoneText,
youcannowLoginWithCredentialsText,
thankYouForJoiningUsText,
menyText,
signUpText,
myAccountText,
myBookmarkedRecipesText,
SignUpToBakesoftText,
howDoesItWorkText,
liveSignUpText,
confirmPasswordText,
currencyNLanguageText,
liveSignupText,
buyOneCreditText,
unlockedRecipesText,
accountDetailsText,
receiptText,
savedRecipesText,
endDateText,
deleteSavedRecipeText,
slideToUnlockText,
unlockedText2,
logoutOnlyText,
youreLoggedOutText,

JoinBakesoftText,
createAccountTodayText,
plattformOfferingText,
signUpOfferingText,
joinBakesoftNowText,
accountDoneSetupText,
loginemailandpassText,
topublishRecipesOnLiveText,
bakesoftSignUp1,
bakesoftSignUp2,
bakesoftSignUp3,
bakesoftSignUp4,
bakesoftSignUp5,
termsAndConditionsText,
gotToLoginText,
serviceS2Text,
serviceS3Text,
serviceS4Text,
updateLanguageHeader,
submitButtonChangeLanguageText,
kitchenTimerText,
liveSignUpText1,
showHideText,
forgotPasswordText,
resetPassText,
resetLinkText,
unlockRecipeText,
recipeUnlockedText,
availableInText,
languagesText,
translateTo16LanguagesText,
proChefText,
voteRegisteredText,
copyLinkText,
pinterestText,
instagramText,
twitterText,
facebookText,
welcomeToBakesoftText1,
proRecipesText,
updaterecipeText,
nutritionalText,
signUpAndBonusText,
signupbonusText,
nocommitmentText,
aboutUsLiveText,
  } = selectedCountry.common || {};


const{
    welcomeToBakesoftText,
    unlockWorldOfCulinaryCreativityText,
    discoverAmazingRecipesText,
    liveBakesoftText,
    affordableAccessText,
    customizationText,
    shareConnectText,
    globalCuisineText,
    signUpNowText,
    forRecipeCreatorsText,
    createRecipesEarnMoneyText,
    advancedRecipeManagementText,
    setYourPriceText,
    trackEarningsText,
    usersResponsibleText,
    startCreatingText,
    readMoreInTermsText,
    affordableAccessHeader,
    customizationHeader,
    shareandConnectHeader,
    globalCuisineHeader,
}=selectedCountry.bakesoftPromotion||{};

// end date functionality when we add  a unlocked recipe to the object  in the local storage
useEffect(() => {
   const endDate = new Date(); // Get the current date
   endDate.setDate(endDate.getDate() + 30);
   const isoDate = endDate.toISOString(); // Convert to ISO 8601 format

  setEndDate(isoDate);
}, []);

useEffect(()=>{
  // let localcountry = 'fi'//localStorage.getItem('LoginLiveCountry')||'se' // this is the country code
  // localStorage.setItem('DefaultLiveCountry',localcountry)
  // setCountry(localcountry)
  // let savedUserRecipes=localStorage.getItem('UnlockedRecipes')

  let puserid=localStorage.getItem('LoginLiveUserId')
  if(puserid){
    setUserid(puserid)
  }



  // here we need to check if the token is still valid
  // no we have to decode the token if th token is the there
  // first check if we have token, if token we check the time validity
  let token=localStorage.getItem('LoginLiveToken')
  if(token){
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp < currentTime) {
      localStorage.removeItem('LoginLiveToken')
      localStorage.removeItem('LoginLiveUserId')
      localStorage.removeItem('LoginLiveName')
      localStorage.removeItem('LoginLiveCountry')
      localStorage.removeItem('LoginLiveCurrency')
      localStorage.removeItem('LoginLiveCredits')
      localStorage.removeItem('UnlockedRecipes')
      setShowLogin(true)
      setShowUnlockButton(false)
      setShowLogoutButton(false)
    }
  }
},[])



    //error report
const RunReportErrHandler = async (errCode) => {
  setSpinnerLoading(true);
  await useReportErr('ErrReport',{errCode});
  setSpinnerLoading(false);
};

useEffect(() => {
  if (getAlertCode > 0) {
    RunReportErrHandler(getAlertCode);
  }
}, [getAlertCode]);

const handleCloseAlert = () => {
  setShowAlert(false);
};


useEffect(() => {
//1745x870
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
  //alert(window.innerWidth + 'x' + window.innerHeight);
    if(window.innerWidth < 460){
      setMobileIsUsed(true)
      setSizeVal(1)
    }else if(window.innerWidth >400 && window.innerWidth < 900){
      setSizeVal(2)
      setMobileIsUsed(false)
  }else if(window.innerWidth >900 && window.innerWidth < 1200){
    setMobileIsUsed(false)
    setSizeVal(3)
  }else if(window.innerWidth >1200 && window.innerWidth < 1600){
    setMobileIsUsed(false)
    setSizeVal(4)
  }
  else if(window.innerWidth >1600 && window.innerWidth < 2000){
    setMobileIsUsed(false)
    setSizeVal(5)
  }else if(window.innerWidth >2000 && window.innerWidth < 2400){
    setMobileIsUsed(false)
    setSizeVal(6)
  }else{
  setMobileIsUsed(false)
  setSizeVal(0)
}
  }

  // Call setSize initially to set the correct state
  setSize();
  // Add the event listener
  window.addEventListener('resize', setSize);
  // Return a cleanup function to remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('resize', setSize);
  };
}, []);

useEffect(() => {
  let promoStatus=localStorage.getItem('bakesoftPromo')
  if(promoStatus==undefined||promoStatus==null||promoStatus>0){
    setBakesoftPromo1(true)
  }else{
    setBakesoftPromo1(false)
    localStorage.setItem('bakesoftPromo',0)
  }
}, []);

// end size

async function GetRecipes() {
  setSpinnerLoading(true);
  const apiKey = process.env.REACT_APP_LIVEKEY;

  try {
    const response = await usePostData1(`livestreamBucketImages`, { offset: offsetRef.current }, apiKey);

    if (response.status === 200) {
      // Handle successful response
      setData((prevData) => [...prevData, ...response.data.data]); // Append new data
      setOffset(response.data.offset);
      offsetRef.current = response.data.offset; // Update offset
    }
  } catch (error) {
    if (error.response && error.response.status === 429) {
      // Handle rate limit error
      setAlertCode(300026);
      setAlertMessage(selectedError[300026]);
      setShowAlert(true);
    } else {
      console.error('Error fetching recipes:', error);
    }
  } finally {
    setSpinnerLoading(false);
  }
}


useEffect(() => {
  const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
                  GetRecipes(); // Fetch more recipes when the user reaches the bottom
                } 
  });

  const sentinel = document.querySelector('#scroll-sentinel');
  if (sentinel) observer.observe(sentinel);

  return () => observer.disconnect();
}, []);






// useEffect(() => {
//   if(offset >limit){
//   //  setLimitReached(true)
//   }
// }, [offset]);



    async function GetLiveCreators() {
      let defaultcountry=localStorage.getItem('DefaultLiveCountry')||'se'

     setSpinnerLoading(true);
     let apiKey = process.env.REACT_APP_LIVEKEY;
      try{
           let response = await useGetData1(`liveCreators/${defaultcountry}`,{},apiKey);
          // let response = await useGetData(`liveCreators/${defaultcountry}`,{});
        if(response.status===200){
            // setSpinnerLoading(false);
            setLiveCreators(response.data[0])
            setLiveCurrencyData(response.data[1])
            setLiveCountryData(response.data[2])
          //  setLiveMenu(response.data[3])
            setServiceInfo(response.data[3])
            setServiceInfoXchangeRate(response.data[4])
            setS5_ServiceInfo(response.data[5])
            setLimit(response.data[6][0].systemVal||200)  // scroll limit from live settings
            setLanguageCountries(response.data[7])
        }else if(response.status===401){
          setSpinnerLoading(false);
          alert('Unauthorized access')
        }
      
    }catch(error){
      setSpinnerLoading(false);
      if (error.response && error.response.status === 429) {
        // Handle rate limit error
        setAlertCode(300026);
        setAlertMessage(selectedError[300026]);
        setShowAlert(true);
      }
  }
  }



  let requestCountry='';
  if(localStorage.getItem('DefaultLiveCountry')===null){
   requestCountry=browserLang
  }else if(localStorage.getItem('DefaultLiveCountry')){
    requestCountry=localStorage.getItem('DefaultLiveCountry')
  }
  
  async function GetRecipeData(recipeId,CreatorUserid) {

    setSpinnerLoading(true);

    let apiKey = process.env.REACT_APP_LIVEKEY;
   let response = await useGetData1(`getLiveRecipe/${recipeId}/${CreatorUserid}/${requestCountry}/${userid}`,{},apiKey);
   
    if(response.status===200){
    setRecipeData(response.data[0]);
    setRecipeHeaderData(response.data[1]);
    setRecipePrice(response.data[2][0].price)
    setCreatorXchangeRate(response.data[3][0].xchangeRate)
    setPriceInLocalCurrency(response.data[2][0].price/response.data[3][0].xchangeRate)
    setCreatorCurrencyShort(response.data[3][0].currencyShort)
    setServiceInfo(response.data[4])
    setChosenImage(response.data[6][0].thumbnailid)
    setCreditsToDebitCustomer(response.data[7][0].creditsToDebitCustomer)
    setNutrientData(response.data[8])
  

    // Set the initial checked state
    const initialChecked = Array(response.data[0].length).fill(2); // Default all rows to 2
    setChecked(initialChecked); // Set the initial array


    if (response?.data?.[0]?.[0]?.image) {
      setChosenImage(response?.data?.[0]?.[0]?.image);
      setRecipeLanguage(response.data[1][0].cc)
     // setRecipeLanguage('pl')

  }


    let userid=localStorage.getItem('LoginLiveUserId')
    if(userid){
    setRecipeUnlocked(response.data[5][0].recipeAccess)
    }  
  //

    setSpinnerLoading(false);
  }else if(response.status===299){
    setShowLogin(true)
    setSpinnerLoading(false);
  }
}




useEffect(() => {
  GetLiveCreators();
}, []);

useEffect(() => {
 //we dount run the image fetch when we get a callback from stripe
  GetRecipes();
  
}, []);



async function GetPreSignedImageUrl(recipeid,userid) {
  setSpinnerLoading(true);
  let apiKey = process.env.REACT_APP_LIVEKEY;
  try{
  let response = await useGetData1(`getPreSignedImageUrl/${recipeid}/${userid}`,{},apiKey);
  if(response.status===200){
    setPresignedImageUrl(response.data.url)
    setSpinnerLoading(false);
  }else if(response.status===401){
    setSpinnerLoading(false);
    setAlertCode(300003)
    setAlertMessage(selectedError[300003])
    setShowAlert(true)
  }
}catch{
setSpinnerLoading(false);
setAlertCode(300003)
setAlertMessage(selectedError[300003])
setShowAlert(true)
}
}



const handleImageClick = (recipeid,userid,item,benaemn,translate,socialmedia) => {
  setShowModal(true); // Show the modal on click
  setSpinnerLoading(true);
  GetRecipeData(recipeid,userid);
  setChosenRecipeId(recipeid);
  setCreatorUserid(userid);
 // setChosenImage(item);
 setChosenRecipeName(benaemn);
 //setShowNutrientModal(false);  // we activate the modal
 if(!socialmedia)
 recipeSeenHandler(recipeid,userid);

 if(translate===1){
  setTranslateMode(true)
}
}

const handleShareClick = (recipeid,userid) => {
  setShowShareModal(true); // Show the modal on click
  GetPreSignedImageUrl(recipeid,userid);
   setSocialSharedData({recipeid:recipeid,userid:userid});
   setShowShareModal(true);

}


useEffect(() => {
  if (blsrid&&blsuid) {  // if we have the parameters recipeid,userid and imageid
     console.log('blsrid',blsrid)
      console.log('blsuid',blsuid)
    setShowModal(true); // Show the modal on click
    setChosenRecipeId(blsrid);
    setCreatorUserid(blsuid);
    GetRecipeData(blsrid,blsuid);

  }
}, [blsrid, blsuid]);



async function LoginUser() {
  let apiKey = process.env.REACT_APP_LIVEKEY;
  setSpinnerLoading(true);
  try {
    const response = await usePostData1('loginLiveUser', { email:email, password:getPassword, }, apiKey);
    if (response.data.success) {
      setUserUnlockedRecipes(response.data.results[1])
      setUserTransactionData(response.data.results[2])
    //  // Storing Recipes in localStorage
          localStorage.setItem('UnlockedRecipes', JSON.stringify(response.data.results[1]));
          
          
    //       setSpinnerLoading(false);
    //       // Store the token and additional user data
          localStorage.setItem('LoginLiveToken', response.data.token);
    //       // decoding
          const decodedToken = jwtDecode(response.data.token);
          //userid
          const userId = decodedToken.user;
          localStorage.setItem('LoginLiveUserId', userId);
          setUserid(userId);
          // email 
          const email = decodedToken.email;
          localStorage.setItem('LoginLiveEmail', email);
          //name
          const name = decodedToken.name;
          localStorage.setItem('LoginLiveName', name);
          //country
          const country = decodedToken.country;
          localStorage.setItem('LoginLiveCountry', country);
          localStorage.setItem('DefaultCountry',country)
          //currency
          const currency = decodedToken.currency;
          localStorage.setItem('LoginLiveCurrency', currency);
          //credits
          const credits=decodedToken.credits
          localStorage.setItem('LoginLiveCredits',credits)
          setShowLogin(false);
          setShowUnlockButton(true);
          setShowLogoutButton(true);
          setShowLoginByUserSelectChoice(false)
           setSpinnerLoading(false);
          
}else if(response.status===401){
  setSpinnerLoading(false);
  setAlertCode(300001)
  setAlertMessage(selectedError[300001])
  setShowAlert(true)
}
}catch(error){
  if (error.response && error.response.status === 429) {
    // Handle rate limit error
    setAlertCode(300026);
    setAlertMessage(selectedError[300026]);
    setShowAlert(true);
  }else{
  setSpinnerLoading(false);
  setAlertCode(300001)
  setAlertMessage(selectedError[300001])
  setShowAlert(true)
}
}
}

async function CreditPaymentHandler(){
  setSpinnerLoading(true);
  const apiKey=process.env.REACT_APP_LIVEKEY;
  try{
    const response= await usePostData1('creditPayment',{userid:userid,recipeId:ChosenRecipeId,creatorUserid:CreatorUserid},apiKey)

    if(response.status===200){
      setSpinnerLoading(false);
      localStorage.setItem('LoginLiveCredits',response.data.ReturnCredits)  // update the credits
                // here we have to add the recipe to the unlocked recipes in local storage
                let tempUnlockedRecipes =JSON.parse(localStorage.getItem('UnlockedRecipes'))
                let myNewObjArraytemp = [...tempUnlockedRecipes];
                myNewObjArraytemp.push({receptid:ChosenRecipeId,endDate:endDate,benaemn:chosenRecipeName});
                localStorage.setItem('UnlockedRecipes', JSON.stringify(myNewObjArraytemp));
                        // we have to add the recipe to the unlocked recipes
                        let myNewObjArray = [...userUnlockedRecipes];
                        myNewObjArray.push({receptid:ChosenRecipeId,endDate:endDate,benaemn:chosenRecipeName});
                      setUserUnlockedRecipes(myNewObjArray)
 
      setRecipeUnlocked(true)  // recipe is unlocked
      setTrigger(prev => prev + 1);  // to generate state update
      setShowLogin(false)   // close the login modal
      setPaymentModal(false) // close the payment modal
    }else if(response.status===401){
      setSpinnerLoading(false);
      setAlertCode(300002)
      setAlertMessage(selectedError[300002])
      setShowAlert(true)
    }
  }catch(error){
    if (error.response && error.response.status === 429) {
      // Handle rate limit error
      setAlertCode(300026);
      setAlertMessage(selectedError[300026]);
      setShowAlert(true);
    }else{
    setSpinnerLoading(false);
    setAlertCode(300002)
    setAlertMessage(selectedError[300002])
    setShowAlert(true)
  }
}
}

const PaymentModalHandler = () => {
  setPaymentModal(true);
  }

  const LogoutHandler = () => {
    localStorage.removeItem('LoginLiveToken')
    localStorage.removeItem('LoginLiveUserId')
    localStorage.removeItem('LoginLiveName')
  //  localStorage.removeItem('LoginLiveCountry')
    localStorage.removeItem('LoginLiveCurrency')
    localStorage.removeItem('LoginLiveCredits')
    localStorage.removeItem('UnlockedRecipes')
    localStorage.removeItem('LoginLiveEmail')
    localStorage.removeItem('DefaultLiveCountry')
    setShowLogin(true)
    setShowUnlockButton(false)
    setShowLogoutButton(false)
    setLoggedOutMessage(true)
    setRecipeUnlocked(false)
  }

//   async function GetNutrientData(recipeid,userid) {
//     setSpinnerLoading(true);
//     let apiKey = process.env.REACT_APP_LIVEKEY;
//     try{
//     let response = await useGetData1(`getNutrientData/${recipeid}/${userid}`,{},apiKey);
//     console.log(response)
//     if(response.status===200){
//   setNutrientData(response.data[0])
//   //setNutrientName(response.data[1])
//   setSpinnerLoading(false);
// }else if(response.status===401){
//   setSpinnerLoading(false);
//   setAlertCode(300008)
//   setAlertMessage(selectedError[300008])
//   setShowAlert(true)
// }
// }catch(error){
//   setSpinnerLoading(false);
//   setAlertCode(300008)
//   setAlertMessage(selectedError[300008])
//   setShowAlert(true)
// }
//   }





const toggleRowExpansion = (index,ingredid,userid) => {
  if(localStorage.getItem('LoginLiveToken')){
  setExpandedRow(expandedRow === index ? null : index);
  setChosenIngredient(ingredid)
}
  
};

const toggleAddingredExpansion = () => {
  if(localStorage.getItem('LoginLiveToken')){
  setExpandedAddingredRow(expandedIngredRow === true? false : true);

}

  
};


const menuHandler=(menuid)=>{
  if(menuid==1){ // login
    setShowLoginByUserSelectChoice(true)
    setMenuSelection(menuid);
    setForceUpdate(prev => prev + 1);
  }else if (menuid==2){ // logout
    if(localStorage.getItem('LoginLiveToken')){
    setShowLoginByUserSelectChoice(false)
    setShowLogoutByUserSelectChoice(true) 
    setMenuSelection(menuid);  // to generate state update
    setForceUpdate(prev => prev + 1);  // to generate state update
  }else {
    setAlertCode(0)
    setAlertMessage('You are already logged out!')
    setShowAlert(true)
  }
  }else if(menuid==3){  // user account
    if(localStorage.getItem('LoginLiveToken')){
    setShowLoginByUserSelectChoice(false)
    setShowLogoutByUserSelectChoice(false)
    setShowAccountByUserSelectChoice(true)
    setMenuSelection(menuid);  // to generate state update
    setForceUpdate(prev => prev + 1);  // to generate state update
  }else{
    setAlertCode(0)
    setAlertMessage('You need to login to view your account')
    setShowAlert(true)
  }
  }else if(menuid==4){
    setData([])// clear the data
         if(localStorage.getItem('LoginLiveToken')){
               if(userid>0){
                   GetRecipesByBookmark(userid)
              }else{
                 userid=localStorage.getItem('LoginLiveUserId')
                  GetRecipesByBookmark(userid)
      }
    }else{
      setAlertCode(0)
      setAlertMessage('You need to login to view your bookmarked recipes')
      setShowAlert(true)
    }
  }else if(menuid==5){
    setShowSignUp(true)
}else if(menuid==6){
  setShowJoinBakesoftAdd(true)
}else if(menuid==7){
  setShowTerms(true)
}else if(menuid==8){
  setShowKitchenTimer(true)
}else if(menuid==9){
  setShowAboutPage(true)

}
}



const closeLoginHandler=()=>{
  setShowLoginByUserSelectChoice(false)
  setShowForgotModal(false)
}

const closeLogoutHandler=()=>{
  setShowLogoutByUserSelectChoice(false)
}

const closeUserAccountHandler=()=>{
  setShowAccountByUserSelectChoice(false)
}


// likes
async function Like(recipeid,userid){
  console.log('recipeid',recipeid)
  console.log('userid',userid)
  let apiKey = process.env.REACT_APP_LIVEKEY;
  setSpinnerLoading(true);
try{
  let response = await usePostData1('likeRecipe',{receptid:recipeid,userid:userid},apiKey)
  console.log(response)
  if(response.status===200){
    let recipeid = response.data?.[0]?.[0]?.returnpreceptid;
    console.log('recipeid',recipeid)
    let myNewObjArray = [...getData];
    let myNewObjIndex = myNewObjArray.findIndex(obj => obj.receptid === recipeid);
    myNewObjArray[myNewObjIndex].likeCount = response.data?.[0]?.[0]?.numLikes;
    // UPDATE 
    setData(myNewObjArray);

    setSpinnerLoading(false);
  }else if(response.status===401){
    setSpinnerLoading(false);
    setAlertCode(300004)
    setAlertMessage(selectedError[300004])
    setShowAlert(true)
  }
}catch(error){
  setSpinnerLoading(false);
  setAlertCode(300004)
  setAlertMessage(selectedError[300004])
  setShowAlert(true)
}
}

const LikeHandler=(recipeid,userid)=>{
  Like(recipeid,userid)
}


async function RecipeSeen(recipeid,userid){
  setSpinnerLoading(true);
  let apiKey = process.env.REACT_APP_LIVEKEY;
  try{
    let response = await usePostData1('recipeSeen',{receptid:recipeid,userid:userid},apiKey)

    if(response.status===200){
      setSpinnerLoading(false);
      let recipeid = response.data?.[0]?.[0]?.recipeNum;
      let myNewObjArray = [...getData];
      let myNewObjIndex = myNewObjArray.findIndex(obj => obj.receptid === recipeid);
      myNewObjArray[myNewObjIndex].seen +=1;
      setData(myNewObjArray);
    }

  }catch(error){
    setSpinnerLoading(false);
    setAlertCode(300006)
    setAlertMessage(selectedError[300024])
    setShowAlert(true)
  }
}


const recipeSeenHandler=(recipeid,userid)=>{
RecipeSeen(recipeid,userid)
}


async function Bookmarker(recipeid){
  setSpinnerLoading(true);
  //let apiKey = process.env.REACT_APP_LIVEKEY;
  let savedBookMarkUserid=localStorage.getItem('LoginLiveUserId')
  try{
  //  let response = await usePostData1('bookmarkRecipe',{receptid:recipeid,userid:savedBookMarkUserid},apiKey)
  let response = await usePostData('bookmarkRecipe',{receptid:recipeid,userid:savedBookMarkUserid})
  
    console.log(response)
    if(response.status===200){
      setSpinnerLoading(false);

// Create a copy of the data
const updatedData = getData.map(obj => {
  // Check if the recipe ID matches
  if (obj.receptid === recipeid) {
    // Toggle bookmarkid based on its current value
    return {
      ...obj,
      bookmarkid: obj.bookmarkid > 1 ? 0 : recipeid
    };
  }
  return obj; // Return other objects unchanged
});
// Update the state with the new data
setData(updatedData);

    }else if(response.status===401){
      setSpinnerLoading(false);
      setAlertCode(300007)
      setAlertMessage(selectedError[300007])
      setShowAlert(true)
    }
  }catch(error){
    console.log(error)
    setSpinnerLoading(false);
    setAlertCode(300007)
    setAlertMessage(selectedError[300007])
    setShowAlert(true)
  }
}


const bookMarkHandler=(recipeid,userid)=>{
 if(userid>0){
if(localStorage.getItem('LoginLiveToken')){
 Bookmarker(recipeid,userid)
 
}else {
  setAlertCode(0)
  setAlertMessage('You need to login to bookmark this recipe')
  setShowAlert(true)

}
 }
}


const changeIngredientValueHandler=(ingredid,val,index)=>{
  setShowUpdateButton(true)
  if(checked[index]===2){
    // here we update the quantity of the ingredient
  if(val===1){
  let mynewObjArr=[...recipeData]
  let mynewObjIndex=mynewObjArr.findIndex(obj=>obj.ingredid===ingredid)
  let mangd=parseFloat(mynewObjArr[mynewObjIndex].mangd)
  let newMangd=mangd+1
  mynewObjArr[mynewObjIndex].mangd=newMangd
  setRecipeData(mynewObjArr)
}else if(val===2){
  let mynewObjArr=[...recipeData]
  let mynewObjIndex=mynewObjArr.findIndex(obj=>obj.ingredid===ingredid)
  let mangd=parseFloat(mynewObjArr[mynewObjIndex].mangd)
  let newMangd=mangd-1
  mynewObjArr[mynewObjIndex].mangd=newMangd
  setRecipeData(mynewObjArr)
}else if(val===3){
  let mynewObjArr=[...recipeData]
  let mynewObjIndex=mynewObjArr.findIndex(obj=>obj.ingredid===ingredid)
  let mangd=parseFloat(mynewObjArr[mynewObjIndex].mangd)
  let newMangd=mangd+10
  mynewObjArr[mynewObjIndex].mangd=newMangd
  setRecipeData(mynewObjArr)
}else if(val===4){
  let mynewObjArr=[...recipeData]
  let mynewObjIndex=mynewObjArr.findIndex(obj=>obj.ingredid===ingredid)
  let mangd=parseFloat(mynewObjArr[mynewObjIndex].mangd)
  let newMangd=mangd-10
  mynewObjArr[mynewObjIndex].mangd=newMangd
  setRecipeData(mynewObjArr)
}

}else if(checked[index]===1){
  // here we update latest_price and its the koeff or kiloprice for the ingredient
  if(val===1){
    let mynewObjArr=[...recipeData]
    let mynewObjIndex=mynewObjArr.findIndex(obj=>obj.ingredid===ingredid)
    let latest_pris=parseFloat(mynewObjArr[mynewObjIndex].latest_pris)
    let newPrice=latest_pris+1
    mynewObjArr[mynewObjIndex].latest_pris=newPrice
    setRecipeData(mynewObjArr)
}else if(val===2){
    let mynewObjArr=[...recipeData]
    let mynewObjIndex=mynewObjArr.findIndex(obj=>obj.ingredid===ingredid)
    let latest_pris=parseFloat(mynewObjArr[mynewObjIndex].latest_pris)
    let newPrice=latest_pris-1
    mynewObjArr[mynewObjIndex].latest_pris=newPrice
    setRecipeData(mynewObjArr)

}else if(val===3){
  let mynewObjArr=[...recipeData]
  let mynewObjIndex=mynewObjArr.findIndex(obj=>obj.ingredid===ingredid)
  let latest_pris=parseFloat(mynewObjArr[mynewObjIndex].latest_pris)
  let newPrice=latest_pris+10
  mynewObjArr[mynewObjIndex].latest_pris=newPrice
  setRecipeData(mynewObjArr)
}else if(val===4){
  let mynewObjArr=[...recipeData]
  let mynewObjIndex=mynewObjArr.findIndex(obj=>obj.ingredid===ingredid)
  let latest_pris=parseFloat(mynewObjArr[mynewObjIndex].latest_pris)
  let newPrice=latest_pris-10
  mynewObjArr[mynewObjIndex].latest_pris=newPrice
  setRecipeData(mynewObjArr)
}
}else if(checked[index]===3){
  // here we update the kaloriekoeff
  if(val===1){
    let mynewObjArr=[...recipeData]
    let mynewObjIndex=mynewObjArr.findIndex(obj=>obj.ingredid===ingredid)
    let kaloriekoeff=parseFloat(mynewObjArr[mynewObjIndex].kaloriekoeff)
    let newKoeff=kaloriekoeff+1
    mynewObjArr[mynewObjIndex].kaloriekoeff=newKoeff
    setRecipeData(mynewObjArr)
}else if (val===2){
    let mynewObjArr=[...recipeData]
    let mynewObjIndex=mynewObjArr.findIndex(obj=>obj.ingredid===ingredid)
    let kaloriekoeff=parseFloat(mynewObjArr[mynewObjIndex].kaloriekoeff)
    let newKoeff=kaloriekoeff-1
    mynewObjArr[mynewObjIndex].kaloriekoeff=newKoeff
    setRecipeData(mynewObjArr)
}else if(val===3){
  let mynewObjArr=[...recipeData]
  let mynewObjIndex=mynewObjArr.findIndex(obj=>obj.ingredid===ingredid)
  let kaloriekoeff=parseFloat(mynewObjArr[mynewObjIndex].kaloriekoeff)
  let newKoeff=kaloriekoeff+10
  mynewObjArr[mynewObjIndex].kaloriekoeff=newKoeff
  setRecipeData(mynewObjArr)
}else if(val===4){
  let mynewObjArr=[...recipeData]
  let mynewObjIndex=mynewObjArr.findIndex(obj=>obj.ingredid===ingredid)
  let kaloriekoeff=parseFloat(mynewObjArr[mynewObjIndex].kaloriekoeff)
  let newKoeff=kaloriekoeff-10
  mynewObjArr[mynewObjIndex].kaloriekoeff=newKoeff
  setRecipeData(mynewObjArr)
}
}
}

const saveUserRecipeHandler = (recipeid) => {
  setRecipeSaved(true);  // we have saved the recipe
  setShowUpdateButton(false); // when we have saved the recipe we hide the update button
  // Ensure `recipeData` is valid and not already parsed
  const receipetosave = recipeData; // Assume `recipeData` is already an object
  // Save to localStorage
  localStorage.setItem(`recipeid:${recipeid}`, JSON.stringify(receipetosave));
  localStorage.setItem(`recipeHeader:${recipeid}`, JSON.stringify(recipeHeaderData));

  

};

const unlockSavedUserRecipeHandler = (recipeid) => {
  // Retrieve the saved recipe from localStorage
  const savedRecipe = JSON.parse(localStorage.getItem(`recipeid:${recipeid}`));
  const savedRecipeHeaderData = JSON.parse(localStorage.getItem(`recipeHeader:${recipeid}`));
  setRecipeData(savedRecipe);
  setRecipeHeaderData(savedRecipeHeaderData);
  localStorage.removeItem(`recipeid:${recipeid}`);
  localStorage.removeItem(`recipeHeader:${recipeid}`);
  setRecipeUnlocked(true);
}


// async function GetKalorieKoeff(ingredid){
//   setSpinnerLoading(true);
//   let apiKey = process.env.REACT_APP_LIVEKEY;
//   try{
//     let response = await useGetData1(`getKalorieKoeff/${ChosenRecipeId}/${ingredid}/${CreatorUserid}`,{},apiKey);
//     console.log(response)
//      if(response.status===200){
//       setSpinnerLoading(false);
//       return response.data
//     }else if(response.status===401){
//       setSpinnerLoading(false);
//       setAlertCode(300008)
//       setAlertMessage(selectedError[300008])
//       setShowAlert(true)
//     }
//   }catch(error){
//     setSpinnerLoading(false);
//     setAlertCode(300008)
//     setAlertMessage(selectedError[300008])
//     setShowAlert(true)
//   }

const addUserOwnIngredientNameHandler=async (name) => {
     setUserOwnIngredName(name)

}

const addUserIngredientHandler=async () => {
  setShowUpdateButton(true)
  setRecipeSaved(false)
  let mynewObjRecipeData=[...recipeData]

  function generateRandomId(length = 5) {
    const min = 10000;
    const max = 99999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

  mynewObjRecipeData.push
  ({
    ADsum:0,
    arborder:0,
    benaemn:userOwnIngredName,
    btext:null,
    ingredid:generateRandomId(),
    kaloriekoeff:0,
    kalories:null,
    latest_pris:0,
    mangd:1,
    quantity:1,
    receptid:ChosenRecipeId,
  })
  setRecipeData(mynewObjRecipeData)
  setExpandedAddingredRow(expandedIngredRow === true? false : true);
}

  const setCheckedHandler = (index, value) => {
  setChecked((prev) => {
    const updated = [...prev];
    updated[index] = value;
    return updated;
  });
};



async function SetUpAccount(){
  setSpinnerLoading(true);
  let apiKey = process.env.REACT_APP_LIVEKEY;
  try{
    let response = await usePostData1(`setUpAccountLiveUser`,{email:email,clientSecret:clientSecret,name:name,
      password:getPassword,confirmPassword:getConfirmPassword},apiKey);
    console.log(response)
    if(response.status===200){
      let credits=response.data.returnedCredits
      localStorage.setItem('LoginLiveCredits',credits)
      // we have to add the recipe to the unlocked recipes
      // let myNewObjArray = [...userUnlockedRecipes];
      //  myNewObjArray.push({receptid:ChosenRecipeId,endDate:endDate,benaemn:chosenRecipeName});
      // setUserUnlockedRecipes(myNewObjArray)

      setSpinnerLoading(false);
    }else if(response.status===298){
      setSpinnerLoading(false);
      setAlertCode(300012)
      setAlertMessage(selectedError[300012])
      setShowAlert(true)
    }else if(response.status===299){
      setSpinnerLoading(false);
      setAlertCode(300011)
      setAlertMessage(selectedError[300011])
      setShowAlert(true)
    }
  }catch(error){
    if (error.response && error.response.status === 429) {
      // Handle rate limit error
      setAlertCode(300026);
      setAlertMessage(selectedError[300026]);
      setShowAlert(true);
    } else {  
    
    setSpinnerLoading(false);
    setAlertCode(300013)
    setAlertMessage(selectedError[300013])
    setShowAlert(true)
  }
}
}


const setUpAccountHandler=()=>{
  SetUpAccount()
}




async function AddPayedCreditsToCustomer(){
  setSpinnerLoading(true);
  //let apiKey = process.env.REACT_APP_LIVEKEY;
  let storedEmail=localStorage.getItem('LoginLiveEmail')
  try{
   // let response = await usePostData1(`addPayedCreditsToCustomer`,{email:storedEmail,clientSecret:clientSecret},apiKey);
    let response = await usePostData(`addPayedCreditsToCustomer`,{email:storedEmail,clientSecret:clientSecret});

    console.log(response)
    if(response.status===200){
      setSpinnerLoading(false);
      let credits=response.data.returnedCredits
      localStorage.setItem('LoginLiveCredits',credits)
      // we have to add the recipe to the unlocked recipes
      let myNewObjArray = [...userUnlockedRecipes];
       myNewObjArray.push({receptid:ChosenRecipeId,endDate:endDate,benaemn:chosenRecipeName});
      setUserUnlockedRecipes(myNewObjArray)

    }else if(response.status===401){
      setSpinnerLoading(false);
      setAlertCode(300010)
      setAlertMessage(selectedError[300010])
      setShowAlert(true)
    }
  }catch(error){
    setSpinnerLoading(false);
    setAlertCode(300010)
    setAlertMessage(selectedError[300010])
    setShowAlert(true)
  }
}




const addCreditsToCustomerHandler=async ()=>{
  AddPayedCreditsToCustomer()
}

const closeCompletePageHandler=()=>{
  setStripePay(false)
  setStripePayment(false)
  setPaymentModal(false)
  setRecipeUnlocked(true)
  setSpinnerLoading(false)
  setSelectedService('s1') // we retun to the first service
  setcloseCompletePage(false)  // close the complete page
  setShowSignUp(false)
}


// const joinBakesoftHandler=()=>{
// // first check if the user is already in the system
// CheckUserEmail()
// }

// async function CheckUserEmail(){
//   let storedEmail=localStorage.getItem('LoginLiveEmail')
//   let apiKey=process.env.REACT_APP_LIVEKEY
//   setSpinnerLoading(true)
//   try{
//     const response=await usePostData1('checkLiveUserEmail',{email:storedEmail},apiKey)
//     console.log(response)
//     if(response.status===200){
   
//       setSpinnerLoading(false)
//       CreatBsUserByLive()
  
//     }else if(response.status===299){
//       setAlertCode(300011)
//       setAlertMessage(selectedError[300011])
//       setShowAlert(true)
//       setSpinnerLoading(false)
//     }
//   }catch(error){
//     setSpinnerLoading(false)
//     setAlertCode(300011)
//     setAlertMessage(selectedError[300011])
//     setShowAlert(true)
//   }
// }
// let storedEmail;
//     storedEmail=localStorage.getItem('LoginLiveEmail')
// if(!storedEmail){
//   storedEmail=email // if user want logged in to live we use the email user gives in the sign up form
// }

// async function CreatBsUserByLive(){
//   console.log('CreatBsUserByLive')
//   setSpinnerLoading(true);
//   let apiKey = process.env.REACT_APP_LIVEKEY;
//   try{
//     let response = await usePostData1(`createBsUserAccountByLive`,{
//       email:storedEmail,
//     },apiKey);
//   console.log(response)
//     if(response.status===200){
//       setBakesoftSetup(true)
//       setSpinnerLoading(false);

//     }else if(response.status===299){
//       setSpinnerLoading(false);
//       setAlertCode(300011)
//       setAlertMessage(selectedError[300011])
//       setShowAlert(true)
//     }
//   }catch(error){
//     setSpinnerLoading(false);
//     setAlertCode(300013)
//     setAlertMessage(selectedError[300013])
//     setShowAlert(true)
//   }
// }


async function GetRecipesByBookmark(userid) {
  setSpinnerLoading(true);
 // let apiKey = process.env.REACT_APP_LIVEKEY;
  //let response = await usePostData1(`livestreamBucketImagesByBookmark`, {userid:userid}, apiKey);
  let response = await usePostData(`livestreamBucketImagesByBookmark`, {userid:userid});
  if (!response || !response.data.data || !Array.isArray(response.data.data)) {
    console.error("Invalid response data:", response);
    setSpinnerLoading(false);
    return;
}
setLimit(response.data.data.offset)  // we set this so the popup window for signup for all recipes will not show
setData(response.data.data);
setSpinnerLoading(false);
}





async function GetRecipesBySearch(recipeid,creatorId) {
  let apiKey = process.env.REACT_APP_LIVEKEY;
  setSpinnerLoading(true);
  try{
  let response = await usePostData1(`livestreamBucketImagesBySearch`, {
    recipeId:recipeid,
    creatorId:creatorId,
  },apiKey);
  console.log(response)
  if(response.status===200&&response.data.data.length>0){
setLimit(response.data.data.offset)  // we set this so the popup window for signup for all recipes will not show
setData(response.data.data)
setSpinnerLoading(false);
  }else{
    setSpinnerLoading(false);
    setAlertCode(300018)
    setAlertMessage(selectedError[300018])
    setShowAlert(true)
  }
}catch{
  setSpinnerLoading(false);
  setAlertCode(300017)
  setAlertMessage(selectedError[300017])
  setShowAlert(true)
}
}



const setFetchRecipeIdHandler=(recipeid,creatorId)=>{
  setChosenSmartSearchRecipeId(recipeid)
  setChosenSmartSearchCreatorId(creatorId)
  GetRecipesBySearch(recipeid,creatorId)
}








const getRecipesBySearchHandler=()=>{
  // here we chck if the search string is empty
  if(searchString.length>0&&searchString!==null&&searchString!==undefined&&searchString!==''&&searchString.length<46){
    setData([]) // clear the data
  GetRecipesBySearch()
  }else{
    setAlertCode(0)
    setAlertMessage('Enter text or text is too long')
    setShowAlert(true)
  }
}

const setSearchStringHandler=(searchString)=>{
  setSearchString(searchString)
}

const showNutrientModalHandler=()=>{
  setShowNutrientModal(true)
}

const changeIngredientNameHandler = (ingredid, newIngredName) => {
  setShowUpdateButton(true)
  // Create a new array to ensure immutability
  let updatedIngredients = recipeData.map((ingredient) =>
    ingredient.ingredid === ingredid
      ? { ...ingredient, benaemn: newIngredName } // Update the matching ingredient
      : ingredient
  );

  setRecipeData(updatedIngredients);
};

const deleteIngredientHandler = (ingredid) => {
  setShowUpdateButton(true);
  // Create a new array excluding the ingredient with the given `ingredid`
  const updatedRecipeData = recipeData.filter(obj => obj.ingredid !== ingredid);
  // Update the state with the new array
  setRecipeData(updatedRecipeData);
};

const closeSignUpHandler=()=>{
  console.log('closeSignUpHandler')
  setShowSignUp(false)
}

async function TranslateRecipe(savedRecipe){
  setSpinnerLoading(true);
 // let apiKey = process.env.REACT_APP_LIVEKEY;
  try{
   // let response = await usePostData1(`translateRecipe`,{recipeData:savedRecipe,language:languageToTranslateTo},apiKey);
    let response = await usePostData(`translateRecipe`,{recipeData:savedRecipe,language:languageToTranslateTo});
  
    if(response.status===200){
      setSpinnerLoading(false);
       try {
      const  translatedData = JSON.parse(response.data.content);
      const { recipeName, description } = translatedData;
      const updatedRecipeData = recipeData.map(item => {
        // Find the translated ingredient
        const translation = translatedData.ingredients.find(t => t.ingredid === item.ingredid);
    
        // Return a new object with updated `benaemn` if translation exists, otherwise return the original item
        return translation 
          ? { ...item, benaemn: translation.benaemn } 
          
          : { ...item };
      });

    
      // Update state
      setRecipeData(updatedRecipeData);
      let myNewObjArray = [...recipeHeaderData];
        const updatedHeader = {
          ...myNewObjArray[0], // Copy the original properties
          benaemn: translatedData.recipeName, // Update recipe name
          btext: translatedData.description,  // Update description
        };
        myNewObjArray[0] = updatedHeader;
        setRecipeHeaderData(myNewObjArray);
      setShowUpdateButton(true) 
      setTranslateMode(false) 
      ChargeUserForTranslation() 
    } catch (error) {
      console.error("Error parsing translated data:", error);
    }

     // setTranslateMode(false)
    }else if(response.status===401){
      setTranslateMode(false)
      setSpinnerLoading(false);
      setAlertCode(300015)
      setAlertMessage(selectedError[300015])
      setShowAlert(true)
    }
  }catch(error){
    setTranslateMode(false)
    setSpinnerLoading(false);
    setAlertCode(300015)
    setAlertMessage(selectedError[300015])
    setShowAlert(true)
  }
}


const translateRecipeHandler = () => {
  setTranslateMode(true); // Enable translation mode

  // Retrieve the saved recipe from localStorage
  const savedRecipe = localStorage.getItem(`recipeid:${ChosenRecipeId}`);

  // Make a copy of the recipe data for processing
  const originalRecipeData = [...recipeData];
  
  // Determine the data to translate
  let dataToTranslate = [];
  
  if (savedRecipe && originalRecipeData.length === 0) {
    // If a saved recipe exists and no data is in `recipeData`, use the saved recipe
    dataToTranslate = JSON.parse(savedRecipe);
  } else {
    // Otherwise, use the existing recipe data
    dataToTranslate = originalRecipeData;
  }
  const translatePayload = {
    recipeName: recipeHeaderData[0]?.benaemn, // Replace with dynamic recipe name if available
    description: recipeHeaderData[0]?.btext, // Replace with dynamic recipe description if available
    ingredients: dataToTranslate.map(({ ingredid, benaemn }) => ({ ingredid, benaemn })),
  };

  TranslateRecipe(translatePayload);
};

async function ChargeUserForTranslation(){
  setSpinnerLoading(true);
  //let apiKey = process.env.REACT_APP_LIVEKEY;
  try{
   // let response = await usePostData1(`chargeUserForTranslation`,{userid:userid,service:'s6'},apiKey);
    let response = await usePostData(`chargeUserForTranslation`,{userid:userid,service:'s6'});
  
    if(response.status===200){
      const credits=response.data.credits
      console.log('credits',credits)
      localStorage.setItem('LoginLiveCredits',credits)
      setSpinnerLoading(false);
      setTranslateMode(false)
    }
  }catch(error){
    setSpinnerLoading(false);
    setTranslateMode(false)
  }
}
async function UpdateUserLanguageCode(){
  setSpinnerLoading(true);
 // let apiKey = process.env.REACT_APP_LIVEKEY;
  try{
  //  let response = await usePostData1(`updateUserLanguageCode`,{userid:userid,languageCode:updateNewLanguageCodeToUser},apiKey);
    let response = await usePostData(`updateUserLanguageCode`,{userid:userid,languageCode:updateNewLanguageCodeToUser});
  
    if(response.data.langCodeReturn===1){
      let ccCodeToSet=response.data.ccCodeToReturn
      localStorage.setItem('LoginLiveCountry', ccCodeToSet);
      localStorage.setItem('DefaultCountry',ccCodeToSet)
      setUpdateNewLanguageCodeToUser('')
      setSpinnerLoading(false);
      setAlertCode(0)
      setAlertMessage('Language code updated')
      setShowAlert(true)
    }else{
      setSpinnerLoading(false);
      setAlertCode(300016)
      setAlertMessage(selectedError[300016])
      setShowAlert(true)

    }
  }catch(error){
    setSpinnerLoading(false);
    setAlertCode(300016)
    setAlertMessage(selectedError[300016])
  }


}

const changeToANewLanguageCodeHandler=(language)=>{
  console.log('language',language)
  setUpdateNewLanguageCodeToUser(language)
}
const kitchenTimerHandler=()=>{
  // toggle the kitchen timer
  setShowKitchenTimer(prev=>!prev)
}

const UnlockRecipeAfterPayment=()=>{  
  setRecipeUnlocked(true);
  setTrigger(prevData=>prevData+1)
}

// const toggleIngredRowExpansion = () => {
//   setExpandedIngredTot(prevData=>prevData===true?false:true)
// }
const toggleIngredRowExpansion = () => {
  setExpandedIngredTot((prevData) => {
    console.log("Before toggle:", prevData);
    const newData = !prevData;
    console.log("After toggle:", newData);
    return newData;
  });
};


const liveSignupHandler=()=>{
  setShowSignUp(true)
  setShowJoinBakesoftAdd(false)
  setBakesoftPromo1(false)
  localStorage.setItem('bakesoftPromo',0)

}

const closeBakesoftPromotionHandler=()=>{
      setShowJoinBakesoftAdd(false)
      localStorage.setItem('bakesoftPromo',0)
      setBakesoftPromo1(false)

}

const forgotPasswordHandler=()=>{
  setShowForgotModal(true)
  setShowLoginByUserSelectChoice(false)
}

useEffect(() => {
  if(showForgotModal===true){
    GetResetLink()
  }
}, [showForgotModal]);


async function GetResetLink(){
  setSpinnerLoading(true);
  try{
    let response = await usePostData('getResetLink',{});
    if(response.status===200){
      setResetToken(response.data.resetLinkToken)
      setSpinnerLoading(false);
    }
  }catch(error){
    setSpinnerLoading(false);
    setAlertCode(300025)
    setAlertMessage(selectedError[300025])
    setShowAlert(true)
  }
}


const UpdatePasswordHandler=()=>{
  UpdateUserPass()
}

async function UpdateUserPass(){
  setSpinnerLoading(true);
 // let apiKey = process.env.REACT_APP_RESET_PASS_KEY;
  try{
    let response = await usePostData2(`updateUserPass`,{email:email},resetToken);
    if(response.status===200){
      setSpinnerLoading(false);
      setAlertCode(0)
      setAlertMessage(resetLinkText)
      setShowAlert(true)
    }else if(response.status===201){
      setSpinnerLoading(false);
      setAlertCode(300020)
      setAlertMessage(selectedError[300020])
      setShowAlert(true)
    }else if(response.status===202){
      setSpinnerLoading(false);
      setAlertCode(300021)
      setAlertMessage(selectedError[300021])
      setShowAlert(true)
    }
  }catch(error){
    setSpinnerLoading(false);
    setAlertCode(300019)
    setAlertMessage(selectedError[300019])
    setShowAlert(true)
  }
}




const handleChefClick = (creatorId) => {
  setShowChefProfile(true);
  setChosenChef(creatorId);
  GetRecipesByChef(creatorId)
}

async function GetRecipesByChef(userid) { 
  setSpinnerLoading(true);
  let apiKey = process.env.REACT_APP_LIVEKEY;
  let response = await usePostData1(`livestreamBucketImagesByChef`, {userid:userid}, apiKey);
  console.log(response)
  if (!response || !response.data.data || !Array.isArray(response.data.data)) {
    console.error("Invalid response data:", response);
    setSpinnerLoading(false);
    return;
}
setLimit(response.data.data.offset)  // we set this so the popup window for signup for all recipes will not show
setData(response.data.data);
setSpinnerLoading(false);
}
// console.log(chosenChef)
// console.log(voteNum)

useEffect(() => {
  if(voteNum>0){ // is user has voted we call the backend
  ChefVote(chosenChef,voteNum)
  }
}, [voteNum]);

async function ChefVote(chosenChef,voteNum){
  setSpinnerLoading(true);
  let apiKey = process.env.REACT_APP_LIVEKEY;
  try{
  let response = await usePostData1(`chefVote`, {creatorId:chosenChef,voteNum:voteNum}, apiKey);
  console.log(response)
  if(response.status===200){
    setSpinnerLoading(false);
    setAlertCode(0)
    setAlertMessage(voteRegisteredText)
    setShowAlert(true)
  }
}catch(error){
  if (error.response && error.response.status === 429) {
    // Handle rate limit error
    setAlertCode(300026);
    setAlertMessage(selectedError[300026]);
    setShowAlert(true);
  } else {
  setSpinnerLoading(false);
  setAlertCode(300022)
  setAlertMessage(selectedError[300022])
  setShowAlert(true)
}
}
}


useEffect(() => {
  if(showChefProfile===true){
  GetChefProfile(chosenChef)
  }
}, [showChefProfile]);

async function GetChefProfile(chosenChef){
  let apiKey = process.env.REACT_APP_LIVEKEY;
setSpinnerLoading(true);
try{
  let response = await usePostData1('getChefProfile',{creatorId:chosenChef},apiKey)
  if(response.status===200){
    setChefProfileData(response)
    setSpinnerLoading(false);
  }else if(response.status===401){
    setSpinnerLoading(false);
    setAlertCode(300023)
    setAlertMessage(selectedError[300023])
    setShowAlert(true)
  }

}catch(error){
  if (error.response && error.response.status === 429) {
    // Handle rate limit error
    setAlertCode(300026);
    setAlertMessage(selectedError[300026]);
    setShowAlert(true);
  } else {
  setSpinnerLoading(false);
  setAlertCode(300023)
  setAlertMessage(selectedError[300023])
  setShowAlert(true)
}
}
}



useEffect(() => {
let chosenLanguageCountry=languageCountries.find(obj=>obj.cc==browserLang)
if(chosenLanguageCountry!==undefined){
let{benaemn,cc}=chosenLanguageCountry

if(getRecipeLanguage==cc){
  setRecipeLanguageMatch(true)
  setUserCountry(cc)
}
}
}, [getRecipeLanguage]);




  return (
<div>
<MyContext.Provider value={{
 appearance:appearance,
  setClientUpdate:setClientUpdate,
  setTransactionId:setTransactionId,
  setCustomerId:setCustomerId,
  stripePromise:stripePromise,
  setName:setName,
  setEmail:setEmail,
  email:email,
  name:name,
  setSelectedService:setSelectedService,
  getSelectedService:getSelectedService,
  setSpinnerLoading:setSpinnerLoading,
  getSpinnerLoading:getSpinnerLoading,
  setPassword:setPassword,
  password:getPassword,
  setConfirmPassword:setConfirmPassword,
  getConfirmPassword:getConfirmPassword,
  liveCurrencyData:liveCurrencyData,
  liveCountryData:liveCountryData,
  setCurrency:setCurrency,
  currency:currency,
  setCountry:setCountry,
  country:country,
  ChosenRecipeId:ChosenRecipeId,
  CreatorUserid:CreatorUserid,
  //setCreatorProfileImage:setCreatorProfileImage,
  //CreatorProfileImage:CreatorProfileImage,
  setCustomerPrice:setCustomerPrice,
  getCustomerPrice:getCustomerPrice,
  setCurrencyName:setCurrencyName,
  getCurrencyName:getCurrencyName,
  LoginUser:LoginUser,
  showLogin:showLogin,
  setShowLogin:setShowLogin,
  stripePay:stripePay,
  setStripePay:setStripePay,
  stripePayment:getStripePayment,
  setStripePayment:setStripePayment,
  isCreateLogin:isCreateLogin,
  setIsCreateLogin:setIsCreateLogin,
  showUnlockButton:showUnlockButton,
  setShowUnlockButton:setShowUnlockButton,
  CreditPaymentHandler:CreditPaymentHandler,
  recipePrice:recipePrice,
  setRecipePrice:setRecipePrice,
  priceInLocalCurrency:priceInLocalCurrency,
  creatorCurrencyShort:creatorCurrencyShort,
  serviceInfo:serviceInfo,
  getPaymentModal:getPaymentModal,
  setPaymentModal:setPaymentModal,
  PaymentModalHandler:PaymentModalHandler,
  setRecipeUnlocked:setRecipeUnlocked,
  recipeUnlocked:recipeUnlocked,
  setShowModal:setShowModal,
  setRecipeAccess:setRecipeAccess,
  recipeAccess:recipeAccess,
  LogoutHandler:LogoutHandler,
  setShowLogoutButton:setShowLogoutButton,
  showLogoutButton:showLogoutButton,
  expandedRow:expandedRow,
  toggleRowExpansion:toggleRowExpansion,
  setExpandedRow:setExpandedRow,
  menuHandler:menuHandler,
closeLoginHandler:closeLoginHandler,
loggedOutMessage:loggedOutMessage,
closeLogoutHandler:closeLogoutHandler,
chosenImage:chosenImage,
setChosenImage:setChosenImage,
showShareModal:showShareModal,
socialSharedData:socialSharedData,
getPresignedImageUrl:getPresignedImageUrl,
setShowShareModal:setShowShareModal,
recipeSeenHandler:recipeSeenHandler,
nutrientName:nutrientName,
changeIngredientValueHandler:changeIngredientValueHandler,
showUpdateButton:showUpdateButton,
saveUserRecipeHandler:saveUserRecipeHandler,
unlockSavedUserRecipeHandler:unlockSavedUserRecipeHandler,
creatorIngredients:creatorIngredients,
addUserIngredientHandler:addUserIngredientHandler,
recipeSaved:recipeSaved,
setCheckedHandler:setCheckedHandler,
checked:checked,
setUpAccountHandler:setUpAccountHandler,
addCreditsToCustomerHandler:addCreditsToCustomerHandler,

setClientSecret:setClientSecret,
closeCompletePageHandler:closeCompletePageHandler,
userUnlockedRecipes:userUnlockedRecipes,
setStripePayOn:setStripePayOn,
stripePayOn:stripePayOn,
setAlertCode:setAlertCode,
setAlertMessage:setAlertMessage,
setShowAlert:setShowAlert,
selectedError:selectedError,
//joinBakesoftHandler:joinBakesoftHandler,
bakesoftSetup:bakesoftSetup,
setBakesoftSetup:setBakesoftSetup,
closeJoinBakesoftModal:closeJoinBakesoftModal,
setCloseJoinBakesoftModal:setCloseJoinBakesoftModal,
setChecked:setChecked,
userTransactionData:userTransactionData,
getSizeVal:getSizeVal,
showNutrientModal:showNutrientModal,
showNutrientModalHandler:showNutrientModalHandler,
formatViews:formatViews,
changeIngredientNameHandler:changeIngredientNameHandler,
deleteIngredientHandler:deleteIngredientHandler,
expandedIngredRow:expandedIngredRow,
toggleAddingredExpansion:toggleAddingredExpansion,
addUserOwnIngredientNameHandler:addUserOwnIngredientNameHandler,
userOwnIngredName:userOwnIngredName,
closeSignUpHandler:closeSignUpHandler,
S5_ServiceInfo:S5_ServiceInfo,
ServiceInfoXchangeRate:ServiceInfoXchangeRate,
translateRecipeHandler:translateRecipeHandler,
translateMode:translateMode,
setRecipeTobeTranslated:setRecipeTobeTranslated,
setLanguageToTranslateTo:setLanguageToTranslateTo,
setTranslateMode:setTranslateMode,
setShowJoinBakesoftAdd:setShowJoinBakesoftAdd,
setShowLogin:setShowLogin,
JoinBakesoftText:JoinBakesoftText,
createAccountTodayText:createAccountTodayText,
plattformOfferingText:plattformOfferingText,
signUpOfferingText:signUpOfferingText,
joinBakesoftNowText:joinBakesoftNowText,
accountDoneSetupText:accountDoneSetupText,
loginemailandpassText:loginemailandpassText,
topublishRecipesOnLiveText:topublishRecipesOnLiveText,

noDescriptionText:noDescriptionText,
scrollMessageText:scrollMessageText,
scrollMessageText2:scrollMessageText2,
translateRecipeText:translateRecipeText,
translateCostText:translateCostText,
selectLanguageText:selectLanguageText,
noNameAvailableText:noNameAvailableText,
savedRecipeText:savedRecipeText,
clickToUnlockText:clickToUnlockText,
ingredientText:ingredientText,
pricePerKgText:pricePerKgText,
priceText:priceText,
amountText:amountText,
kaloriesText:kaloriesText,
increaseIngredientValueText:increaseIngredientValueText,
decreaseIngredientValueText:decreaseIngredientValueText,
changeNameText:changeNameText,
saveText:saveText,
savedText:savedText,
deleteText:deleteText,
totalText:totalText,
addIngredientText:addIngredientText,
addText:addText,
placeHolderIngredientNameText:placeHolderIngredientNameText,
descriptionText:descriptionText,

unlockThisRecipeText:unlockThisRecipeText,
creditsToUseText:creditsToUseText,
creditsText:creditsText,
noCreditsText:noCreditsText,
   
addMoreCreditsText:addMoreCreditsText,
gotoPaymentText:gotoPaymentText,
chefInFocusText:chefInFocusText,

bsLivePaymentText:bsLivePaymentText,
bsLiveAmountText:bsLiveAmountText,
bsLiveCurrencyText:bsLiveCurrencyText,
bsLivePaymentTermsText:bsLivePaymentTermsText,
paymentSuccessfullText:paymentSuccessfullText,
thankYouForPurchaseText:thankYouForPurchaseText,
selectCurrencyText:selectCurrencyText,
selectLanguageText2:selectLanguageText2,
placeHolderEmailText:placeHolderEmailText,
placeHolderPasswordText:placeHolderPasswordText,
loginText2:loginText2,
logOutText:logOutText,
norecipeAvailableText:norecipeAvailableText,
notAvailableText:notAvailableText,
nameText:nameText,
countryText:countryText,
currencyText:currencyText,
noTransactionsFoundText:noTransactionsFoundText,
paySuccessText:paySuccessText,
yourAccountSetupDoneText:yourAccountSetupDoneText,
youcannowLoginWithCredentialsText:youcannowLoginWithCredentialsText,
thankYouForJoiningUsText:thankYouForJoiningUsText,

liveSignUpText:liveSignUpText,
confirmPasswordText:confirmPasswordText,
currencyNLanguageText:currencyNLanguageText,
liveSignupText:liveSignupText,
buyOneCreditText:buyOneCreditText,
unlockedRecipesText:unlockedRecipesText,
accountDetailsText:accountDetailsText,
receiptText:receiptText,
savedRecipesText:savedRecipesText,
endDateText:endDateText,
deleteSavedRecipeText:deleteSavedRecipeText,
slideToUnlockText:slideToUnlockText,
unlockedText2:unlockedText2,
logoutOnlyText:logoutOnlyText,
youreLoggedOutText:youreLoggedOutText,
bakesoftSignUp1:bakesoftSignUp1,
bakesoftSignUp2:bakesoftSignUp2,
bakesoftSignUp3:bakesoftSignUp3,
bakesoftSignUp4:bakesoftSignUp4,
bakesoftSignUp5:bakesoftSignUp5,
gotToLoginText:gotToLoginText,
serviceS2Text:serviceS2Text,
serviceS3Text:serviceS3Text,
serviceS4Text:serviceS4Text,
creditsToDebitCustomer:creditsToDebitCustomer,
changeToANewLanguageCodeHandler:changeToANewLanguageCodeHandler,
updateNewLanguageCodeToUser:updateNewLanguageCodeToUser,
languageCountries:languageCountries,
UpdateUserLanguageCode:UpdateUserLanguageCode,
updateLanguageHeader:updateLanguageHeader,
submitButtonChangeLanguageText:submitButtonChangeLanguageText,
menyText:menyText,
loginText2:loginText2,
logOutText:logOutText,
myAccountText:myAccountText,
myBookmarkedRecipesText:myBookmarkedRecipesText,
SignUpToBakesoftText:SignUpToBakesoftText,
howDoesItWorkText:howDoesItWorkText,
termsAndConditionsText:termsAndConditionsText,
seenBySocialMedia:seenBySocialMedia,
kitchenTimerText:kitchenTimerText,
showKitchenTimer:showKitchenTimer,
liveSignUpText1:liveSignUpText1,
UnlockRecipeAfterPayment:UnlockRecipeAfterPayment,
trigger:trigger,
mobileIsUsed:mobileIsUsed,
toggleIngredRowExpansion:toggleIngredRowExpansion,
expandedIngredTot:expandedIngredTot,
showHideText:showHideText,
getSizeVal:getSizeVal,
translateRecipeTextGrid:translateRecipeTextGrid,
liveSignupHandler:liveSignupHandler,

welcomeToBakesoftText:welcomeToBakesoftText,
unlockWorldOfCulinaryCreativityText:unlockWorldOfCulinaryCreativityText,
discoverAmazingRecipesText:discoverAmazingRecipesText,
liveBakesoftText:liveBakesoftText,
affordableAccessText:affordableAccessText,
customizationText:customizationText,
shareConnectText:shareConnectText,
globalCuisineText:globalCuisineText,
signUpNowText:signUpNowText,
forRecipeCreatorsText:forRecipeCreatorsText,
createRecipesEarnMoneyText:createRecipesEarnMoneyText,
advancedRecipeManagementText:advancedRecipeManagementText,
setYourPriceText:setYourPriceText,
trackEarningsText:trackEarningsText,
usersResponsibleText:usersResponsibleText,
startCreatingText:startCreatingText,
readMoreInTermsText:readMoreInTermsText,
forgotPasswordText:forgotPasswordText,
forgotPasswordHandler:forgotPasswordHandler,
showForgotModal:showForgotModal,
UpdatePasswordHandler:UpdatePasswordHandler,
resetPassText:resetPassText,
affordableAccessHeader:affordableAccessHeader,
customizationHeader:customizationHeader,
shareandConnectHeader:shareandConnectHeader,
globalCuisineHeader:globalCuisineHeader,
unlockRecipeText:unlockRecipeText,
recipeUnlockedText:recipeUnlockedText,

availableInText:availableInText,
languagesText:languagesText,
translateTo16LanguagesText:translateTo16LanguagesText,
proChefText:proChefText,
selectedError:selectedError,
formatViews:formatViews,
copyLinkText:copyLinkText,
pinterestText:pinterestText,
instagramText:instagramText,
twitterText:twitterText,
facebookText:facebookText,
welcomeToBakesoftText1:welcomeToBakesoftText1,
proRecipesText:proRecipesText,
updaterecipeText:updaterecipeText,
nutritionalText:nutritionalText,
signUpAndBonusText:signUpAndBonusText,

signupbonusText:signupbonusText,
nocommitmentText:nocommitmentText,
aboutUsLiveText:aboutUsLiveText,





}}>
{showKitchenTimer&&(
                <div className="bsLiveCoockingTimerWrapper">
  <KTimer kitchenTimerHandler={kitchenTimerHandler}/>
  </div>
)}
  <div className="bsTotalArea">
      <div className="bsTopArea">
      <img src={bslivelogobig1} className="bsLiveLogoImage" alt="live.bakesoft.se" 
      onClick={()=>window.location.href='https://live.bakesoft.se'}
      />
         <div className="bsNameArea">
         <SnowfallEffect />
          <div className="bsLiveName"
          onClick={()=>window.location.href='https://live.bakesoft.se'}
          >Live.bakesoft.se</div>

          {!mobileIsUsed&&(
              <div className="bsNameText">{headerGreeting}</div>   
          )}
   </div>
      </div>
{showLoginByUserSelectChoice&&(  /* this is the login area */
  <div className="bsLiveTabLoginOnlyArea">
  <LoginUserOnly/>
  </div>
)}
{showForgotModal&&(
    <div className="bsLiveTabLoginOnlyArea">
<ForgotPasswordModal/>
</div>
)}

{showLogoutByUserSelectChoice&&(          /* this is the logout area */
  <div className="bsLiveTabLogoutOnlyArea">
    <LogoutUserOnly/>
    </div>
    )}

{showSignUp&&(
    <Signup/>

)}

{returnCode==1&&closeCompletePage?(
  <div className="bsLiveCallbackArea">
  <AccountSetupCompletePage /> 
  </div>
):(returnCode==2&&closeCompletePage?(
  <div className="bsLiveCallbackArea">
  <CompletePage/>
  </div>
):'')}

<AnimatedChef/>

    
    {showAccountByUserSelectChoice&&localStorage.getItem('LoginLiveToken')?(  /* this is the user account area */
      <div className="bsLiveUserAccountArea">
        <div className="bsLIveUserAccountHeader">Account:&nbsp;{localStorage.getItem('LoginLiveName')}
        <VscClose className="bsLiveCloseLoginOnlyIcon"
                onClick={() =>closeUserAccountHandler(false)}
                />

        </div>
        <UserAccount/>
        </div>
        ):''}

{showShareModal&&(
  <ShareONSocialMedia/>
)}



<CookieWarning />



{offset>limit&&(
    <ScrollLimitMessage 
    setShowSignUp={setShowSignUp} 
    setShowLoginByUserSelectChoice={setShowLoginByUserSelectChoice}
    />  
)}

{showJoinBakesoftAdd||bakesoftPromo1?(
<BakesoftPromotion
liveSignupHandler={liveSignupHandler}
setShowJoinBakesoftAdd={setShowJoinBakesoftAdd}
closeBakesoftPromotionHandler={closeBakesoftPromotionHandler}
/>
):''}


        <div className="bsMiddleArea">
 
         <div className="bsLiveSearchArea">
         <SlideMenu />
    
         <SmartSearchInput setFetchRecipeIdHandler={setFetchRecipeIdHandler}/>  
         </div>

    </div>

    {!recipeLanguageMatch && /* Only show if there's no match */
  !closeLanguageSelector &&
  !localStorage.getItem("LoginLiveCountry") &&
  !seenBySocialMedia && (
    /* This is the language selector area */
    <div className="bsLiveLanguageSelectorWrapper">
      <LanguageSelector
        languageCountries={languageCountries}
        getSelectedLanguage={getSelectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        setCloseLanguageSelector={setCloseLanguageSelector}
        setTrigger={setTrigger}
        trigger={trigger}
      />
    </div>
  )}

    
    {showTerms&&(
<div className="bsLiveTermsWrapper">
<TermsComponent Terms={Terms}
setShowTerms={setShowTerms}
/>
</div>
)}

{showChefProfile&&(
<ChefProfile
setVotenum={setVotenum}
chefProfileData={chefProfileData}
setShowChefProfile={setShowChefProfile}
/>
)}

{showAboutPage&&(
<AboutPage
selectedLanguage={userCountry}
/>
)}



              <div className="bsGridArea">
                      {getData.map((item, index) => (
                            <div className="bsGridItem" key={index}>
                              <div className="bsGridFrame">
                                <img src={item?.image} className="bsGridImage"
                                loading="lazy"
                                
                               onClick={()=>handleImageClick(item.receptid,item.userId,item,item.benaemn,0)}
                                />
                                
                                <div className={`bsChefHat ${
                                       item?.diff ==1  
                                      ? 'chef-bg-green'
                                      :item?.diff == 2
                                      ? 'chef-bg-orange'
                                      :item?.diff ==3
                                      ? 'chef-bg-red'
                                      : ''
                                  }`}
                                  title={`Difficulty: ${
                                    item.diff === 1
                                      ? 'Easy'
                                      : item.diff === 2
                                      ? 'Medium'
                                      : item.diff === 3
                                      ? 'Hard'
                                      : 'Unknown'
                                  }`}
                                >
                                  🧑‍🍳
                                </div>

                          <div className={`bsLiveBookmark ${item.bookmarkid>0 ? 'mark-green':''}`}
                                          title={inputTitleBookmark}
                                          onClick={()=>bookMarkHandler(item.receptid,item.userId)}
                                          >
                                                <Bookmark className="bsLiveBookmarkIcon"/>
                          </div>
                          <div className="bsLiveChefImageWrapper"
                             onClick={()=>handleImageClick(item.receptid,item.userId,item,item.benaemn,0)}
                          >
                          <div className="bsLiveChefImageLink" title={imageTitleChef}
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent click event from propagating to parent
                              handleChefClick(item.userId);
                            }}
                          >
                              <img src={item.imagelink} className="bsLiveChefImage"
                              loading="lazy"
                            
                              />                   
                          </div>
                          </div>

                                <div className="bsRecipeHeader" key={`header-${index}`}>
                                     {item.benaemn || 'No name available'}
                                </div>
                                <div className="bsRecipeHeaderChefName" key={`chefname-${index}`}>
                                     {item.fullName || 'No name available'}
                                </div>
                                <div className="bsLiveTranslateLangAreaWrapper">
                                    <div className="bsRecipeLang">
                                    <MapPin className="bsLiveAvailableLangIcon"/>
                                    <div className="bsLiveAvailableLangText">{item.recipeLanguage}</div>
                                    </div>

                                    <div className="bsLiveTranslateLang">
                                    <Globe className="bsLiveAvailableLangIcon"/>
                                    <div className="bsLiveAvailableLangText" key={index}
                          onClick={()=>handleImageClick(item.receptid,item.userId,item,item.benaemn,1)}  // 1 mean translate
                                      >{translateRecipeTextGrid}</div>
                                    </div>
                                </div>
                                <div className="bsRecipeSocialArea">
                        
                            <div className="bsLiveSocialAreaIconsWrapper">

                       <div className="bsLiveTimeWrapper">
                            <div className="bsLiveTimeContainer">  
                                  <Clock  className="bsLiveTimeIcon"
                                  title={titleShareOnSocialMedia}
                                  />&nbsp;
                                  <div  className="bsLiveTimeCount">{item.timeTo||0}{textMinutesInShort}</div>
                                  </div>
                        </div>
                           <div className="bsLiveOtherContainerWrapper">
                                  <div className="bsLiveLikeContainer">
                                  <Heart className="bsLiveSocialHeartIcon"
                                  title={titleTextLikeRecipe}
                                  onClick={()=>LikeHandler(item.receptid,item.userId)}
                                  />&nbsp;
                                    <div  className="bsLiveLikeCount">{item.likeCount||0}</div>
                                  </div>

                                  <div className="bsLiveSeenContainer">
                                      <Eye className="bsLiveSocialSeenIcon"
                                      title={titleSeenByVievers}
                                      onClick={()=>recipeSeenHandler(item.receptid,item.userId)}
                                      />&nbsp;
                                      <div  className="bsLiveSeenCount">{formatViews(item.seen||0)}</div>
                                    </div> 
                                  <div className="bsLiveSocialShareContainer">  
                                        <Share2  className="bsLiveSocialShareIcon"
                                        title={titleShareOnSocialMedia}
                                        onClick={()=>handleShareClick(item.receptid,item.userId)}
                                        />
                                  </div>  
                          </div> 
                 
                      </div>
                            
                                </div>
                                
                           </div>
 
                         
              </div>          
))}
    <div id="scroll-sentinel"></div>
      </div>

      {showModal && (
  
        <ShowLiveRecipe recipeData={recipeData} recipeHeaderData={recipeHeaderData} 
        setShowModal={setShowModal} seenBySocialMedia={seenBySocialMedia}
        nutrientData={nutrientData}
        />

    )}

    
    <div className="bsLiveSpinnerContainer">
        <RotatingLines strokeColor="gold" strokeWidth="2" animationDuration="1" width="100" visible={getSpinnerLoading} />
    </div>
      {/* this alert is for the error messages*/}
      {showalert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}

      />:''}

    
    {getSpinnerLoading&&(
        <div className="bslivexvspinner-text">{getSpinnerLoading&&translateMode?scrollMessageText:scrollMessageText2}
            <div className="bsLiveloading-dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
  

    </div>
    )}



    
    </div>

    </MyContext.Provider>
  </div>
  );


}

function ShowLiveRecipe({ recipeData, recipeHeaderData, setShowModal, blurCoefficient =1,
  seenBySocialMedia,nutrientData
 }) {
  let ctx = useContext(MyContext);
  let tokenCheck=localStorage.getItem('LoginLiveToken')

  // here we will acount the total sum of the recipe data
  const[totalData,setTotalData]=useState({totalPrice:0,totalQuantity:0,totalKalories:0})
let totalPrice=0
let totalQuantity=0
let totalKalories=0

useEffect(() => {
  let myNewObjArray = [...recipeData];
  myNewObjArray.map((item,index)=>{
    totalQuantity+=parseFloat(item.mangd)
    totalPrice+=parseFloat(item.latest_pris / 1000 * item.mangd)
    totalKalories+=parseFloat(item.mangd * item.kaloriekoeff)
  })
  setTotalData({totalPrice:totalPrice,totalQuantity:totalQuantity,totalKalories:totalKalories})
},[recipeData]);


  // here we check to see if the recipe is saved in the local storage
 let savedRecipeid=localStorage.getItem(`recipeid:${ctx.ChosenRecipeId}`)
 let savedRecipeHeaderData=localStorage.getItem(`recipeHeader:${ctx.ChosenRecipeId}`)
 let savedRecipeName
 let savedDescription
  if(savedRecipeid){

   recipeData=JSON.parse(savedRecipeid)
   savedRecipeName=JSON.parse(savedRecipeHeaderData)[0]?.benaemn
    savedDescription=JSON.parse(savedRecipeHeaderData)[0]?.btext

  }else{

    recipeData=recipeData
    savedRecipeid=false
    savedRecipeName=recipeHeaderData[0]?.benaemn
    savedDescription=recipeHeaderData[0]?.btext
  }

  // check if user can translate the recipe
  let userCheckToTranslate=true
  let creditsToUse=localStorage.getItem('LoginLiveCredits')
  if(creditsToUse<1||localStorage.getItem('LoginLiveToken')===null){
    userCheckToTranslate=false
  }

  // Function to determine if a cell should be blurred based on the index and blur coefficient
  //const shouldBlur = (index) => index % (3 * blurCoefficient) < blurCoefficient;
  const shouldBlur = (index) => {
    if (localStorage.getItem('LoginLiveToken')&& ctx.recipeUnlocked) {
      return false; // Don't blur if the recipe is paid for
    }
    return index % (2 * blurCoefficient) < blurCoefficient;};
// end blur

/**
 * Rearranges a text with dividers into an array of objects.
 * Dividers are numbers followed by a dot (e.g., 1., 2., 3., etc.).
 * @param {string} text - The input text with dividers.
 * @returns {Array} - An array of objects with {divider, content}.
 */
const rearrangeTextToObjects = (text) => {
  const pattern = /(\d+\.\s)/g; // Matches numbers followed by a dot and a space
  const parts = text.split(pattern).filter(Boolean); // Split by pattern and remove empty entries

  const result = [];
  for (let i = 0; i < parts.length; i += 2) {
    const divider = parts[i].trim(); // The divider (e.g., "1.")
    const content = parts[i + 1] ? parts[i + 1].trim() : ''; // The content after the divider
    result.push({ divider, content });
  }

  return result;
};

const normalizeTextForRearrangement = (text) => {
  // Step 1: Remove unwanted invisible characters
  const cleanedText = text.replace(/[\u200B-\u200D\uFEFF]/g, ''); // Remove zero-width spaces and BOM

  // Step 2: Ensure proper format for "number. " pattern
  const formattedText = cleanedText.replace(/(\d+)\.(?!\s)/g, '$1. '); // Add space after "number."

  // Step 3: Trim leading/trailing whitespace and normalize newlines
  const normalizedText = formattedText
    .trim() // Remove leading/trailing whitespace
    .replace(/\r\n|\r|\n/g, '\n'); // Normalize all line breaks to '\n'

  return normalizedText;
};

// Description blur area
const [descriptionText, setDescriptionText] = useState(ctx.noDescriptionText);
const { obfuscateText } = useTextObfuscator(); // Use the obfuscator
const [rearrangedDescription, setRearrangedDescription] = useState([]); // Holds the rearranged objects

useEffect(() => {
  if (savedDescription) {
    let text = savedDescription || ctx.noDescriptionText;

    // Apply obfuscation if needed (e.g., if the user is not logged in)
    if (!tokenCheck||!ctx.recipeUnlocked) {
      text = obfuscateText(text, 0.5, '*'); // Obfuscate 40% of the text
    }

    setDescriptionText(text);
    let normalizedText = normalizeTextForRearrangement(text); // Normalize text for rearrangement
  //  setRearrangedDescription(rearrangeTextToObjects(text)); // Rearrange text into objects
     setRearrangedDescription(rearrangeTextToObjects(normalizedText)); // Rearrange text into objects
  } else {
    setDescriptionText(ctx.noDescriptionText);
    setRearrangedDescription([]); // Clear rearranged description
  }
}, [savedDescription, ctx.noDescriptionText,tokenCheck, obfuscateText,ctx.trigger]);




  return (
    <div className="bsLiveModalTop">
      <div className="bsLiveModalCloseIconWrapper">
        <div className="bsLiveModalTopClose">
          <VscClose
            className="bsLiveModalTopCloseIcon"
            onClick={() => {ctx.setShowModal(false);ctx.setChosenImage('');ctx.setExpandedRow(null);ctx.setTranslateMode(false)}}
          />
        </div>
      </div>
      <div className="bsLiveActionAreaWrapper">
      {!ctx.recipeUnlocked&&( 
               <div className="bsLiveActionArea">
                               <UnlockButton
                                width={300}
                                height={40}
                                backgroundColor="#1a1a1a"
                                textColor="#ffffff"
                                sliderColor="#c0c0c0"
                                successColor="#FFEE53"
                                unlockText={ctx.slideToUnlockText}
                                unlockedText2={ctx.unlockedText2}
                                onUnlock={() => ctx.PaymentModalHandler()}
                              /></div>
                           )}
            {ctx.getPaymentModal&&(
              <PaymentHandler/>
            )}
          </div>
          {ctx.translateMode&&ctx.recipeUnlocked&&userCheckToTranslate&&localStorage.getItem('LoginLiveToken')?(
          <><div className="bsLiveTranslateMenuWrapper">
          <div className="bsLiveTranslateMenuText">
            <Globe className="bsLiveTranslateIcon"/>{ctx.translateRecipeText}</div>
            <div className="bsLiveTranslationInfoText">{ctx.translateCostText}</div>
        </div>
        
        <div className="bsLiveTranslateArea">
            <select className="bsLiveTranslateSelect"
              onChange={(e) => ctx.setLanguageToTranslateTo(e.target.value)}
            >
              <option value="0">{ctx.selectLanguageText}</option>
              {ctx.liveCountryData.map((item, index) => (
                <option value={item.currency} key={index}>{item.benaemn}</option>
              ))}
            </select>
            <div className="bsLiveTranslateButton"
              onClick={() => ctx.translateRecipeHandler()}
            >{ctx.translateRecipeTextGrid}</div>
          </div></>
          ):''}
          
      <div className="bsLiveRecipeWrapper">
      <div className="bsLiveRecipeHeaderWrapper">
      <div className="bsLiveRecipeHeader">
     
      <div className="bsLiveRecipeSavedInfoText1">{savedRecipeName || ctx.noNameAvailableText}</div>
      </div>
       
  


      <div className="bsLiveImageAreaWrapper">
      <div className="bsLiveImageArea"><img src={ctx.chosenImage} className="bsLiveShowChosenImage"
           loading="lazy"
      /></div>
{ctx.mobileIsUsed===false?(
 <div className="bsLiveChartWrapper">
<NutrientPieChart nutrientData={nutrientData}
 getSizeVal={ctx.getSizeVal}
/>
</div>
):''}
     
         </div>
            {savedRecipeid&&localStorage.getItem('LoginLiveToken')&&(
            <div className="bsLiveTopRightMessage">
              <div className="bsLiveLockedRecipe">
              
                    <><div className="bsLiveRecipeSavedInfoText"
                        onClick={() => ctx.unlockSavedUserRecipeHandler(ctx.ChosenRecipeId)}
                      >&nbsp;{ctx.savedRecipeText}</div>
                      <div className="bsLiveRecipeSavedUnlockText">{ctx.clickToUnlockText}</div></>      
              </div>
            </div>
                )}
      
      </div>
      
  
      <div className="bsLiveRecipeListWrapper">
        {ctx.mobileIsUsed===false?(
      <div className="bsLiveToggleListButtonWrapper" onClick={() => {ctx.toggleIngredRowExpansion();}}>
          {ctx.expandedIngredTot ? (
                 <FiMinusCircle className="bsLiveToggleIngredListIcon" />
                   ) : (
                 <FiPlusCircle className="bsLiveToggleIngredListIcon" />
                   )}
      </div>
        ):''}


        <div className="bsLiveRecipeListHeader">
          <div className="bsLiveIngredHeaderName">{ctx.ingredientText}</div>
          <div className="bsLiveHeaderKgPrice">{ctx.pricePerKgText}</div>
          <div className="bsLiveHeaderPris">{ctx.priceText}</div>
          <div className="bsLiveHeaderMangd">{ctx.amountText}</div>
          <div className="bsLiveHeaderKalories">{ctx.kaloriesText}</div>
        </div>
        {recipeData.map((item, index) => (
             <div key={index} className={ctx.expandedIngredTot?"bsLiveIngredRowWrapper1":"bsLiveIngredRowWrapper"}>
          <div
            className="bsLiveIngredRow" 
            onClick={() => ctx.toggleRowExpansion(index,item.ingredid,item.userid)}
          >
            <div className={`${index % 2 === 0 ? "bsLiveIngredName" : "bsLiveIngredName1"} ${shouldBlur(index) ? 'bsLiveblurred' : ''}`}
                >
              {item.benaemn}
            </div>
            <div className={`${index % 2 === 0 ? "bsLiveKgPrice" : "bsLiveKgPrice1"} ${shouldBlur(index) ? 'bsLiveblurred' : ''}`}
                >
              {item.latest_pris}
            </div>

            <div className={`${index % 2 === 0 ? "bsLivePris" : "bsLivePris1"} ${shouldBlur(index + 1) ? 'bsLiveblurred' : ''}`}>
            {item.latest_pris && (item.latest_pris / 1000 * item.mangd).toFixed(2)}
            </div>
            <div className={`${index % 2 === 0 ? "bsLiveMangd" : "bsLiveMangd1"} ${shouldBlur(index + 2) ? 'bsLiveblurred' : ''}`}>
              {item.mangd}
            </div>
            <div className={`${index % 2 === 0 ? "bsLiveKalories" : "bsLiveKalories1"} ${shouldBlur(index + 3) ? 'bsLiveblurred' : ''}`}>
              {parseInt(item.mangd * item.kaloriekoeff)}
            </div>
      
          </div>

          <div className={`bsLiveextraInfo ${!savedRecipeid&&ctx.expandedRow === index ? 'expanded' : 'collapsed'}`}>
          <div className="bsLiveNutrientWrapper">
                  <div className="bsLiveNutrientChangeValWrapper">
                      <div className="bsLiveNutrientChangeVal">
                        <div className="bsLiveNutrientChangeValPlus"
                        onClick={()=>ctx.changeIngredientValueHandler(item.ingredid,1,index)}
                        onDoubleClick={()=>ctx.changeIngredientValueHandler(item.ingredid,3,index)}
                        title={ctx.increaseIngredientValueText}
                        ><FaPlus  className="bsLiveChangeIcon"/></div>
                        <div className="bsLiveNutrientChangeValMinus"
                        onClick={()=>ctx.changeIngredientValueHandler(item.ingredid,2,index)}
                        onDoubleClick={()=>ctx.changeIngredientValueHandler(item.ingredid,4,index)}
                        title={ctx.decreaseIngredientValueText}
                        ><FaMinus className="bsLiveChangeIcon"/></div>
                      </div>
                      
                      <div className="bsLiveCheckBoxWrapper">
                      
                          <input type="radio"  name={`checkboxGroup-${index}`} className="bsLiveCheckBox" 
                          
                          checked={ctx.checked[index] === 1}
                          value={'1'}
                          onChange={() => ctx.setCheckedHandler(index, 1)}
                          />
                          <div className="bsLiveRadioText">{ctx.priceText}</div>
                          <input type="radio"  name={`checkboxGroup-${index}`} className="bsLiveCheckBox" 
                          checked={ctx.checked[index] === 2}
                          value={'2'}
                          onChange={() => ctx.setCheckedHandler(index, 2)}
                          />
                          <div className="bsLiveRadioText">{ctx.amountText}</div>
                          <input type="radio"  name={`checkboxGroup-${index}`} className="bsLiveCheckBox" 
                          checked={ctx.checked[index] === 3}
                          value={'2'}
                          onChange={() => ctx.setCheckedHandler(index, 3)}
                          />
                         <div className="bsLiveRadioText">{ctx.kaloriesText}</div>
                        </div>
                        
                     
                </div>
                <div className="bsLiveIngredientChangeNameWrapper">
                    <div className="bsLiveIngredientChangeName">{ctx.changeNameText} &nbsp;
                      <input type="text" className="bsLiveIngredientChangeInput"
                      value={item.benaemn}
                      onChange={(e) =>
                        ctx.changeIngredientNameHandler(item.ingredid, e.target.value)} // Pass updated value
                      />
                               <div className="bsLiveUpdateButtonWrapper">
                        
                          <div className="bsLiveUpdateButton"
                          onClick={()=>ctx.saveUserRecipeHandler(ctx.ChosenRecipeId)}
                          >{ctx.saveText}</div>
                      
                      </div>
                    </div>
                    <div className="bsLiveDeleteIngredientButton"
                    onClick={()=>ctx.deleteIngredientHandler(item.ingredid)}
                    
                    >{ctx.deleteText}</div>
                 

                </div>
          </div>
            </div>
            
            <div/>
          </div>
      ))}
   

                 {/* Show extra info if the row is expanded */}
                 <div className="bsLiveTotalAmountFooterWrapper">
                            <div className="bsLiveTotalAmountFooter">
                                <div className="bsLiveTotalAmountFooterText">{ctx.totalText}</div>
                                <div className="bsLiveTotalAmountFooterTotalPrice"></div>
                                
                                <div className="bsLiveTotalAmountFooterPrice">{totalData.totalPrice.toFixed(2)}</div>
                                <div className="bsLiveTotalAmountFooterQuantity">{totalData.totalQuantity}</div>
                                <div className="bsLiveTotalAmountFooterKalories">{parseInt(totalData.totalKalories)}</div>
                            </div>
                      </div>
            

                 
                 {localStorage.getItem('LoginLiveToken')&&ctx.recipeUnlocked?(
               <div className="bsLiveAddIngredientWrapper">
               <div className="bsLiveAddIngredientHeader"
               onClick={()=>ctx.toggleAddingredExpansion()}
               >{ctx.addIngredientText}</div>
               <div className={`bsLiveIngredInfo ${ctx.expandedIngredRow? 'expanded' : 'collapsed'}`}>
                <div className="bsLiveAddIngredientBox">
                  <input type="text" className="bsLiveAddIngredientInput" placeholder={ctx.placeHolderIngredientNameText}
                  onChange={(e)=>ctx.addUserOwnIngredientNameHandler(e.target.value)}
                  value={ctx.userOwnIngredName}
                  />
                  <div className="bsLiveAddIngredientButton"
                  onClick={()=>ctx.addUserIngredientHandler()}
                  >{ctx.addText}</div>
                  
                </div>

                </div>
            
           

            </div>
              ):null}

            {ctx.showUpdateButton&&( 
            <div className="bsLiveAddIngredientSaveButtonWrapper">
              <div className={ctx.recipeSaved===false?"bsLiveAddIngredientSaveButton":"bsLiveAddIngredientSaveButton1"}
              onClick={()=>ctx.saveUserRecipeHandler(ctx.ChosenRecipeId)}
              >{ctx.recipeSaved===true?ctx.savedText:ctx.saveText}</div>
              </div>
            )}
          
          <div className="bsLiveRecipeTextWrapper">
    <div className="bsLiveRecipeTextHeader">{ctx.descriptionText}</div>
    <div className="bsLiveRecipeTextInner">
  {rearrangedDescription.map((item, index) => (
    <div
      className={`bsLiveRecipeTextRow ${
        item.isBlurred ? "blurred" : ""
      }`}
      key={index}
    >
      <div className="big-dot"></div>
      <div className="content">
        <span className="divider">{item.divider}</span>
        <span>{item.content}</span>
      </div>
    </div>
  ))}
</div>
  
  
    
  </div>
</div>


        </div>
      </div>


  );
}

function PaymentHandler(props){
  /* This component handles the payment modal and choices for the user */
  let {setPaymentModal} = props;
  let ctx = useContext(MyContext);

  // in parameter to be able to calculate the price in local currency
  let mynewObjArray = [...ctx.liveCurrencyData];
  let recipePrice=ctx.recipePrice
  let userCountryCode = localStorage.getItem('LoginLiveCountry')||'se';
  // end parameters

  // object locup to find the creator price on his recipe.
  // we find the exchange rate in the system table.
  // we find the currency short in the system table.
  let myNewObjIndex = mynewObjArray.findIndex((item) => item.cc === userCountryCode);
  let priceInLocalCurrency = recipePrice / mynewObjArray[myNewObjIndex].xchangeRate;
  let currencyShort = mynewObjArray[myNewObjIndex].currencyShort;
  // end lookup





  const setSelectedOptionHandler = (event) => {
   console.log(event)
    ctx.setSelectedService(event)

}


// we will check if the user has credits and has a token
  useEffect(() => {
    let credits=localStorage.getItem('LoginLiveCredits')
    let token=localStorage.getItem('LoginLiveToken')
    if(credits>0&&token){
      ctx.setShowUnlockButton(true)
      ctx.setShowLogin(false)

    }else{
        ctx.setRecipeUnlocked(false)

      }

  }, []);

  const userName = localStorage.getItem('LoginLiveName') || 'Guest'; // Default to 'Guest' if name is null
  const userCredits = Number(localStorage.getItem('LoginLiveCredits')) || 0; // Convert to number or default to 0
  

  return(
    <div className="bsLivePaymentTopWrapper">
           <div className="bsLivePaymentCloseIconWrapper">
                <VscClose className="bsLivePaymentCloseIcon2"
                onClick={() => ctx.setPaymentModal(false)}
                />
          </div>
          <div className="bsLiveCreatorProfileInformation">
            <UserPresentation/>

          </div>
           <hr className="bsLivePaymentHr1"/>
          <div className="bsLivePaymentTop">
                <div className="bsLivePaymentMessage">
                  <div className="bsLivePaymentRadio1"
                   onClick={() =>ctx.setSelectedService('')}
                  >&nbsp;&nbsp;&nbsp;
                  {ctx.unlockThisRecipeText}&nbsp;{priceInLocalCurrency.toFixed(2)} {currencyShort}{'('}{ctx.creditsToDebitCustomer}{ctx.creditsText}{')'}
                </div>
                </div>
            
      
            
          <div className="bsLivePaymentMessage">
              <div className="bsLivePaymentRadio">
                <input type="radio"
                  value='s2'
                  checked={ctx.getSelectedService == 's2'}
                  onChange={(e) =>ctx.setSelectedService(e.target.value)}
                />&nbsp;{ctx.serviceS2Text}
              </div>
          </div>
          

          <div className="bsLivePaymentMessage">
              <div className="bsLivePaymentRadio">
                <input type="radio"
                  value='s3'
                  checked={ctx.getSelectedService == 's3'}
                  onChange={(e) =>ctx.setSelectedService(e.target.value)}
                />&nbsp;{ctx.serviceS3Text}
              </div>
          </div>
        

    


          </div>

          
   


      <div className="bsLivePaymentTop1">
          <div className="bsLivePaymentContactArea">
          {ctx.showLogin&&(
             <TabLogin/>
    )}
    {/* <div className="bsLiveCreditInfo">Welcome&nbsp; {userName} </div> */}
    {ctx.showUnlockButton&&(
      <div className="bsLiveUnlockButtonAreaWrapper">
         {/* Check if the user has credits and show the unlock button */}
        {userCredits> 0 ? (
               <>
            
               <div className="bsLiveUnlockButton" onClick={() => ctx.CreditPaymentHandler()}>
                    {ctx.recipeUnlocked ? ctx.recipeUnlockedText : ctx.unlockRecipeText}
                    {ctx.creditsToUseText} {userCredits}
               </div>
              
            
                {/* <div className="bsLiveWalletIconContainer">
                    <div className='bsLiveUnlockButtonIcon'><SlWallet className="bsLiveLoginWalletIcon" />
                      <span className="bsLiveCreditBadge">{ctx.creditsText}&nbsp;
                        {userCredits}
                      </span>
                    </div>           
               </div> */}

               {(ctx.getSelectedService == 's2' || ctx.getSelectedService == 's3') && (
                  <div className="bsLiveAddMoreCreditsButton"
                    onClick={() => {ctx.PaymentModalHandler();ctx.setStripePay(true);
                    }}
                    >{ctx.addMoreCreditsText}</div>
                )}
     
               </>

) : (           /* If the user has no credits, show the payment button */
   <><div className="bsLiveNoCreditsText">{ctx.noCreditsText}</div>
   <div className="bsLiveGotoPaymentButton"
   onClick={()=>{ctx.PaymentModalHandler();ctx.setStripePay(true)}}
   >{ctx.gotoPaymentText}</div></>
)}

      </div>
    )}
                   
    </div>
    {ctx.stripePay&&(
          <div className="bsLivePaymentTopWrapper">
            <StripePaymentInside/>
          </div>
        )}

  </div>
   {!ctx.closeJoinBakesoftModal&&(
    ctx.getSelectedService == 's4'&&localStorage.getItem('LoginLiveToken')&&(
        <JoinBakesoft/>       
        )
   ) }



    


      </div>
  

  )
}

function UserPresentation(props){
  /* This component displays the creator's profile image and name */
  let ctx = useContext(MyContext);
  
useEffect(() => {
  GetCreatorProfile();
}, []);

const [CreatorProfile, setCreatorProfile] = useState({});
const [creatorProfileImage, setCreatorProfileImage] = useState("");
const[creatorRecipeLanguages,setCreatorRecipeLanguages]=useState(0)
const[creatorNumRates,setCreatorNumRates]=useState(0)
const[creatorSumRates,setCreatorSumRates]=useState(0)
console.log(creatorSumRates,creatorNumRates)

let completeImageUrl = process.env.REACT_APP_CREATORCLOUDFRONT_URL +'/'+ creatorProfileImage;






  async function GetCreatorProfile() {
    const apiKey = process.env.REACT_APP_LIVEKEY;
    ctx.setSpinnerLoading(true);
    try {
     const response = await useGetData1(`getCreatorProfile/${ctx.CreatorUserid}/${ctx.ChosenRecipeId}`, {}, apiKey);
      if(response.status===200){
      setCreatorProfile({
        benaemn: response.data.profile.benaemn,
        fullName: response.data.profile.fullName,
      });
      setCreatorProfileImage(response.data.image);
      setCreatorRecipeLanguages(response.data.numLanguages);
      setCreatorNumRates(response.data.numRating);
      setCreatorSumRates(response.data.sumRating);
      // Stop loading spinner
      ctx.setSpinnerLoading(false);
    }
    } catch (error) {
      if(error.response && error.response.status === 429) {
        // Handle rate limit error
        ctx.setAlertCode(300026);
        ctx.setAlertMessage(ctx.selectedError[300026]);
        ctx.setShowAlert(true);
      }
     
    }
    
  }    

    // const calculateStars = (numRates,sumRates) => {
    //     if (numRates === 0) return 0; // No ratings, no stars
    //     const average = sumRates / numRates;
    //     return Math.ceil(average); // Round up for partial stars
    // }

    // const stars = calculateStars(creatorNumRates, creatorSumRates);


  
  return(
    <div className="bsLiveUserPresentationTopWrapper">
        <div className="bsLiveUserPresentationBox">
            <img src={completeImageUrl&&completeImageUrl} className="bsLiveCreatorProfileImage"
                loading="lazy"/>
          

         <div className="bsLiveUserPresentationInnerBox">
             <div className="bsLiveCreatorProfileName">Chef&nbsp;{CreatorProfile.fullName}
             <div className="bsLiveUserPresentationProChefArea"><ChefHat  className="proChefIcon"/>&nbsp;{ctx.proChefText}</div>
             </div>
            
            <div className="bsLiveUserPresentationStarArea">
                {Array.from({ length:creatorSumRates }, (_, index) => (
                <Star key={index} className="bsLiveUserPresentationStarIcon" />
            ))}
               &nbsp;{ctx.formatViews(creatorNumRates)} reviews
            </div>
            <div className="bsLiveUserPresentationGlobalArea">
                  <Globe className="bsLiveUserPresentationGlobalIcon"/>&nbsp;{ctx.availableInText} {creatorRecipeLanguages||1} {ctx.languagesText}
               
            </div>
            <div className="bsLiveUserPresentationLanguages">
            <Languages className="bsLiveUserPresentationLangIcon"/>({ctx.translateTo16LanguagesText})
            </div>

         </div>
         </div>

    
      <div className="bsLiveCreatorInfoBox">
  
      <div className="bsLiveCreatorProfileText"> {CreatorProfile.benaemn}</div>
      </div>

    </div>
    
  )
}





function StripePayment() {
  let ctx = useContext(MyContext);
  const [clientSecret, setClientSecret] = useState("");
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [returnid]=useState(1) // used to return to the correct page
  let styleappearance=ctx.appearance

  async function GetClientSecret() {
    let apiKey=process.env.REACT_APP_LIVEKEY
    try{//createLive-payment-intent
      const response=await usePostData1('createLive-payment-intent',{country:ctx.country,email:ctx.email,service:ctx.getSelectedService,
        chosenRecipeId:ctx.ChosenRecipeId,creatorUserid:ctx.CreatorUserid,currency:ctx.currency},
      apiKey)
         console.log(response)
        if(response.status===200){
          console.log(response)
          setClientSecret(response.data.clientSecret);
          ctx.setClientSecret(response.data.clientSecret);
          ctx.setClientUpdate(response.data.clientSecret);
          ctx.setTransactionId(response.data.clientSecret,'transactionId')
          ctx.setCustomerId(response.data.customer,'customerId')
          ctx.setSpinnerLoading(false)
          ctx.setCustomerPrice(response.data.customerPrice)
          ctx.setCurrencyName(response.data.currencyName)
        }
      }catch(error){
        ctx.setSpinnerLoading(false)
        if (error.response && error.response.status === 429) {
          // Handle rate limit error
          ctx.setAlertCode(300026);
          ctx.setAlertMessage(ctx.selectedError[300026]);
          ctx.setShowAlert(true);
        }
        
      }
    }
  useEffect(() => {
    if(!clientSecret){
    GetClientSecret();
    }
  }, []);

  const handlePaymentSuccess = () => {
     // ctx.UpdateStripUserPaymentStatus(ctx.getUserId,ctx.getSignupInfo.service,ctx.getSignupInfo.language)
     //ctx.SignUpRequestContinueWith()
      setIsPaymentComplete(true); // Trigger CompletePage render
      ctx.setUpAccountHandler()
  };

  return (
      // <div className="CustomApp-App">
      <div className="bsLiveStripeConfirmBoxWrapper">  
      <div className="bsLiveCustomerInfoBox">
             <div className="bsLiveCustomerInfoHeaderWrapper">
            <div className="bsLiveCustomerInfoTextHeader">{ctx.bsLivePaymentText}</div>
            </div>

            <div className="bsLiveInfoTextWrapper">
            <div className="bsLiveCustomerInfoText">{ctx.bsLiveAmountText}:</div>
             <div className="bsLiveCustomerInfoText2"> {ctx.getCustomerPrice&&ctx.getCustomerPrice.toFixed(2)}</div>
             </div>
             <hr className="bsLivePaymentHr"></hr>
             <div className="bsLiveInfoTextWrapper">
            <div className="bsLiveCustomerInfoText">{ctx.bsLiveCurrencyText}:</ div>
              <div className="bsLiveCustomerInfoText3"> {ctx.getCurrencyName}</div>
      </div>
              
                </div> 


                 {clientSecret && (
                  <Elements stripe={ctx.stripePromise} options={{ clientSecret, appearance: styleappearance }}>
                      {isPaymentComplete 
                          ? <AccountSetupCompletePage /> 
                          : <CheckoutForm onPaymentSuccess={handlePaymentSuccess} clientSecret={clientSecret} returnid={returnid} />
                      }
                  </Elements>
              )}
      </div>
  );
}

function StripePaymentInside() {

  let ctx = useContext(MyContext);

  const [clientSecret, setClientSecret] = useState("");
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const[returnid]=useState(2) // used to return to the correct page
  let styleappearance=ctx.appearance
  let savedCurrency=localStorage.getItem('LoginLiveCountry')
  let savedEmail=localStorage.getItem('LoginLiveEmail')


  async function GetClientSecret() {
    let apiKey=process.env.REACT_APP_LIVEKEY
    try{
      // const response=await usePostData1('createLivePaymentIntentInside',{name:ctx.name,email:savedEmail,service:ctx.getSelectedService,
      //     chosenRecipeId:ctx.ChosenRecipeId,creatorUserid:ctx.CreatorUserid,currency:savedCurrency},
      //   apiKey)
        const response=await usePostData('createLivePaymentIntentInside',{name:ctx.name,email:savedEmail,service:ctx.getSelectedService,
        
          chosenRecipeId:ctx.ChosenRecipeId,creatorUserid:ctx.CreatorUserid,currency:savedCurrency})
          console.log(response)
        if(response.status===200){
          console.log(response)
          setClientSecret(response.data.clientSecret);
          ctx.setClientSecret(response.data.clientSecret);
          // ctx.setClientUpdate(response.data.clientSecret);
          // ctx.setTransactionId(response.data.clientSecret,'transactionId')
          ctx.setCustomerId(response.data.customer,'customerId')
          // ctx.setSpinnerLoading(false)
          ctx.setCustomerPrice(response.data.customerPrice)
          ctx.setCurrencyName(response.data.currencyName)
        }
      }catch(error){
        ctx.setSpinnerLoading(false)
        console.log(error)
      }
    }
  useEffect(() => {
    if(!clientSecret){
    GetClientSecret();
    }
  }, []);

  const handlePaymentSuccess = () => {
     // ctx.UpdateStripUserPaymentStatus(ctx.getUserId,ctx.getSignupInfo.service,ctx.getSignupInfo.language)
     //ctx.SignUpRequestContinueWith()
     ctx.UnlockRecipeAfterPayment()  //unlock the recipe since its payed
      setIsPaymentComplete(true); // Trigger CompletePage render
      ctx.addCreditsToCustomerHandler()  //adding credits to customer account
  };

  return (
      // <div className="CustomApp-App">
      <div className="bsLiveStripeConfirmBoxWrapper">  
      {/* <div className="bsLiveCustomerInfoBox">
            <div className="bsLiveCustomerInfoText">{ctx.bsLivePaymentText}</div>
            <div className="bsLiveCustomerInfoText">{ctx.bsLiveAmountText}: {ctx.getCustomerPrice&&ctx.getCustomerPrice.toFixed(2)}</div>
            <div className="bsLiveCustomerInfoText">{ctx.bsLiveCurrencyText}: {ctx.getCurrencyName}</div>
          
        </div>  */}

<div className="bsLiveCustomerInfoBox">
             <div className="bsLiveCustomerInfoHeaderWrapper">
            <div className="bsLiveCustomerInfoTextHeader">{ctx.bsLivePaymentText}</div>
            </div>

            <div className="bsLiveInfoTextWrapper">
            <div className="bsLiveCustomerInfoText">{ctx.bsLiveAmountText}:</div>
             <div className="bsLiveCustomerInfoText2"> {ctx.getCustomerPrice&&ctx.getCustomerPrice.toFixed(2)}</div>
             </div>
             <hr className="bsLivePaymentHr"></hr>
             <div className="bsLiveInfoTextWrapper">
            <div className="bsLiveCustomerInfoText">{ctx.bsLiveCurrencyText}:</ div>
              <div className="bsLiveCustomerInfoText3"> {ctx.getCurrencyName}</div>
      </div>
              
                </div> 


                 {clientSecret && (
                  <Elements stripe={ctx.stripePromise} options={{ clientSecret, appearance: styleappearance }}>
                      {isPaymentComplete 
                          ? <CompletePage clientSecret={clientSecret} /> 
                          : <CheckoutForm onPaymentSuccess={handlePaymentSuccess} clientSecret={clientSecret} returnid={returnid}/>
                      }
                  </Elements>
              )}
      </div>
  );
}

function CompletePage({ clientSecret }) {
  let ctx = useContext(MyContext)
return (
  <div className="bsLiveStripeCompleteBoxWrapper">
    <PaymentSuccessSmall/>
    {/* <IoCloudDoneOutline className="bsLiveStripeCompleteIcon"/>
    <div className="bsLiveStripeCompleteText">{ctx.paymentSuccessfullText}</div>
    <div className="bsLiveStripeCompleteText">{ctx.thankYouForPurchaseText}</div>
    <div className="bsLiveStripeCompleteText">
    <IoLogOutOutline className="bsLiveCompletePageIcon" 
    onClick={()=>ctx.closeCompletePageHandler()}
    /> */}

    </div>

)


}

function CheckoutForm({ dpmCheckerLink, onPaymentSuccess, clientSecret,returnid }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }

    setIsLoading(true);

    // Confirm payment using the PaymentElement
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Change to your actual completion page
        return_url: `https://live.bakesoft.se/returncall?returnid=${returnid}`,
      },
      redirect: 'if_required', // Redirect to the URL if no additional authentication is required
    });

    // Handle any errors returned during confirmation
    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
      setIsLoading(false);
    } else {
      // Trigger success callback

      onPaymentSuccess();
    }
  };

  const paymentElementOptions = {
    layout: "tabs", // Allow users to switch between payment methods
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button disabled={isLoading || !stripe || !elements} id="submit" >
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
        {/* Display any error messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>

      {/* DEV: Show dynamic payment methods annotation and checker */}
      <div id="dpm-annotation">
  
      </div>
    </>
  );
}

function TabLogin(){
  let ctx = useContext(MyContext);

  const [userEmailStatus,setUserEmailStatus]=useState(false)

async function CheckUserEmail(){
  let apiKey=process.env.REACT_APP_LIVEKEY
  ctx.setSpinnerLoading(true)
  try{
    const response=await usePostData1('checkLiveUserEmail',{email:ctx.email},apiKey)
    if(response.status===200){
      setUserEmailStatus(true)
      ctx.setSpinnerLoading(false)
    }else{
      setUserEmailStatus(false)
      ctx.setAlertCode(300011)
      ctx.setAlertMessage(ctx.selectedError[300011])
      ctx.setShowAlert(true)
      ctx.setSpinnerLoading(false)
    }
  }catch(error){
    if (error.response && error.response.status === 429) {
      // Handle rate limit error
      ctx.setAlertCode(300026);
      ctx.setAlertMessage(ctx.selectedError[300026]);
      ctx.setShowAlert(true);
    }else{
    ctx.setSpinnerLoading(false)
    setUserEmailStatus(false)
    ctx.setAlertCode(300011)
    ctx.setAlertMessage(ctx.selectedError[300011])
    ctx.setShowAlert(true)
  }
}
}
  


  const nameHandler = (name) => {
    ctx.setName(name)
  }
  const emailHandler = (email) => {
    ctx.setEmail(email)
  }
  const checkEmailAddressHandler = async (email) => {
    // check if the email adress is valid
    // check if the email adress is in the right format
    let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if(email.match(emailPattern)){
      await CheckUserEmail()
    
    }
  }

  useEffect(() => {
    if(userEmailStatus){
      // ctx.setShowLogin(false)
      ctx.setStripePayOn(true);
      }
  }, [userEmailStatus]);

  const passwordHandler = (password) => {
    ctx.setPassword(password)
  }
  const confirmPasswordHandler = (password) => {
    ctx.setConfirmPassword(password)
  }

  const setCurrencyHandler = (currency) => {
    ctx.setCurrency(currency)
  }
  const setCountryHandler = (country) => {
    ctx.setCountry(country)
  }

  const LoginHandler = () => {
    ctx.LoginUser()
  }

  return(

    <div className="bsLiveTabContainer">
     
    <div className="bsliveTabs">
      <div
        className={`bsliveTab ${ctx.isCreateLogin ? "bsliveActive" : ""}`}
        onClick={() => ctx.setIsCreateLogin(true)}
      >
        {ctx.liveSignUpText}
      </div>
      <div
        className={`bsliveTab ${!ctx.isCreateLogin ? "bsliveActive" : ""}`}
        onClick={() => ctx.setIsCreateLogin(false)}
      >
      {ctx.loginText2}
      </div>
    </div>
 
    <div className={!ctx.isCreateLogin?"bsLiveTabContentSmall":"bsLiveTabContent"}>

       {ctx.isCreateLogin ? (
              <div className="bsLiveTabInputContentWrapper">
                  <input type="text" className="bsLivePaymentContact" placeholder={ctx.nameText} value={ctx.name}
                  onChange={(e) => nameHandler(e.target.value)} /><input type="text" className="bsLivePaymentContact" placeholder={ctx.placeHolderEmailText} value={ctx.email}
                    onChange={(e) => emailHandler(e.target.value)} /><input type="password" className="bsLivePaymentContact" placeholder={ctx.placeHolderPasswordText} value={ctx.password}
                      onChange={(e) => passwordHandler(e.target.value)} /><input type="password" className="bsLivePaymentContact" placeholder={ctx.confirmPasswordText} value={ctx.getConfirmPassword}
                        onChange={(e) => confirmPasswordHandler(e.target.value)} /><div className="bsLiveExtraText"><hr className="bsLiveHr"></hr>{ctx.currencyNLanguageText}<hr className="bsLiveHr"></hr></div>
                          <div className="bsLiveSelectAreaWrapper">
                           <select className="bsLivePaymentSelectCurrency"
                      onChange={(e) => setCurrencyHandler(e.target.value)}
                    >
                      <option value="0">{ctx.selectCurrencyText}</option>
                      {ctx.liveCurrencyData.map((item, index) => (
                        <option value={item.cc} key={index}>{item.currencyShort}</option>
                      ))}
                    </select> 

                    <select className="bsLivePaymentSelectCurrency"
                      onChange={(e) => setCountryHandler(e.target.value)}
                    >
                      <option value="0">{ctx.selectLanguageText2}</option>
                      {ctx.liveCountryData.map((item, index) => (
                        <option value={item.bslivelanguageid} key={index}>{item.benaemn}</option>
                      ))}
                    </select>
                    </div>
                    <div className="bsLiveLoginButtonChoiceArea">
                    <button className="bsLivePaymentButton1"
          onClick={()=>checkEmailAddressHandler(ctx.email)}
          disabled={ctx.getSelectedService==='s1'&&ctx.email===''||!ctx.getSelectedService&&ctx.name===''||ctx.getSelectedService==='s1'&&ctx.password===''||ctx.getSelectedService==='s1'&&ctx.getConfirmPassword===''||ctx.getSelectedService==='s1'&&ctx.currency===''||ctx.getSelectedService==='s1'&&ctx.country===''||ctx.getSelectedService==='s1'&&ctx.password!==ctx.getConfirmPassword}
          >{ctx.gotoPaymentText}</button>
    
      </div>
    </div>
              
      ) : (
        <div className="bsLiveUserLoginWrapper">
          <input type="email" className="bsLiveUserLoginEmail" 
          placeholder={ctx.placeHolderEmailText} value={ctx.email} autoComplete='email'
           onChange={(e)=>emailHandler(e.target.value)}  
        />
          <input type="password" className="bsLiveUserLoginPass" placeholder={ctx.placeHolderPasswordText} value={ctx.password}
          onChange={(e)=>passwordHandler(e.target.value)}
          />
          <button className="bsLiveLoginButtonUser"
          onClick={()=>LoginHandler()}
          >{ctx.loginText2}</button>
        </div>
      )}
    </div>
  
  
   

        {ctx.stripePayOn&&(
          <div className="bsLivePaymentTopWrapper">
            <StripePayment/>  
          </div>
        )}
        
        </div>

  )
}


function Signup(){
  let ctx = useContext(MyContext);


  let S5_ServiceInfo=ctx.S5_ServiceInfo&&ctx.S5_ServiceInfo
  let ServiceInfoXchangeRate=ctx.ServiceInfoXchangeRate&&ctx.ServiceInfoXchangeRate
  let currencyShort
  let priceInLocalCurrency

  console.log(ctx.getSelectedService)

  const[currency,setCurrency]=useState('')
  const[getPrice,setPrice]=useState(0)

  useEffect(() => {
  if(currency.length>0){
    let currencyData=ServiceInfoXchangeRate.filter(item=>item.currencyShort==currency)
    currencyShort=currency
    priceInLocalCurrency=parseInt(S5_ServiceInfo[0].servicePrice)
     priceInLocalCurrency=priceInLocalCurrency/parseFloat(currencyData[0].xchangeRate)
     if(priceInLocalCurrency>0){
     setPrice(priceInLocalCurrency.toFixed(2))
     }
  }
  }, [currency]);



  const[userEmailStatus,setUserEmailStatus]=useState(false)



async function CheckUserEmail(){
  let apiKey=process.env.REACT_APP_LIVEKEY
  ctx.setSpinnerLoading(true)
  try{
    const response=await usePostData1('checkLiveUserEmail',{email:ctx.email},apiKey)
    if(response.status===200){
      ctx.setSpinnerLoading(false)
      setUserEmailStatus(true)
    }else{
      setUserEmailStatus(false)
      ctx.setAlertCode(300011)
      ctx.setAlertMessage(ctx.selectedError[300011])
      ctx.setShowAlert(true)
      ctx.setSpinnerLoading(false)
    }
  }catch(error){
    ctx.setSpinnerLoading(false)
    setUserEmailStatus(false)
    ctx.setAlertCode(300011)
    ctx.setAlertMessage(ctx.selectedError[300011])
    ctx.setShowAlert(true)
  }
}
  


  const nameHandler = (name) => {
    ctx.setName(name)
  }
  const emailHandler = (email) => {
    ctx.setEmail(email)
  }
  const checkEmailAddressHandler = async (email) => {
    // check if the email adress is valid
    // check if the email adress is in the right format
    let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if(email.match(emailPattern)){
      await CheckUserEmail()
    
    }
  }

  useEffect(() => {
    if(userEmailStatus){
      ctx.setStripePayOn(true);
      }
  }, [userEmailStatus]);

  const passwordHandler = (password) => {
    ctx.setPassword(password)
  }
  const confirmPasswordHandler = (password) => {
    ctx.setConfirmPassword(password)
  }

  const setCurrencyHandler = (currency) => {
    ctx.setCurrency(currency)
  }
  const setCountryHandler = (country) => {
    ctx.setCountry(country)
  }

  const LoginHandler = () => {
    ctx.LoginUser()
  }
  const setSelectedOptionHandler = (option) => {
    console.log(option)
    ctx.setSelectedService(option)
  //   if(option==='s4'){
  //     ctx.setCloseJoinBakesoftModal(false)
     
      
  // }
}

const currency2Handler=(currency)=>{
  setCurrency(currency)
}

  return(
    <div className="bsLiveTabContainerSignWrapper">
    <div className="bsLiveTabContainerSign">
      <div className="bsLiveSignupCloseIconWrapper"
         onClick={() => ctx.closeSignUpHandler()}>
                <VscClose  className="bsLiveCloseSignupIcon"
             
                />
      </div>
    <div className="bsliveTabSign">
      
      <div className="bsliveTabSign bsliveActiveSign">
        {ctx.liveSignUpText1}
        <div className="bsliveSignHeader">{ctx.signupbonusText}!</div>
        <div className="bsliveSignHeader">{ctx.nocommitmentText}.</div>
        
      
        
      </div>
    </div>
 
    <div className="bsLiveTabContentSign">
          <input type="text" className="bsLivePaymentContactSign" placeholder={ctx.nameText} value={ctx.name}
                  onChange={(e) => nameHandler(e.target.value)} /><input type="text" className="bsLivePaymentContactSign" placeholder={ctx.placeHolderEmailText} value={ctx.email}
                    onChange={(e) => emailHandler(e.target.value)} /><input type="password" className="bsLivePaymentContactSign" placeholder={ctx.placeHolderPasswordText} value={ctx.password}
                      onChange={(e) => passwordHandler(e.target.value)} /><input type="password" className="bsLivePaymentContactSign" placeholder={ctx.confirmPasswordText} value={ctx.getConfirmPassword}
                        onChange={(e) => confirmPasswordHandler(e.target.value)} /><div className="bsLiveExtraText"><hr className="bsLiveHr"></hr>{ctx.currencyNLanguageText}<hr className="bsLiveHr"></hr></div>
                               
            <div className="bsLiveSelectAreaWrapper">
                                <select className="bsLivePaymentSelectCurrencySign"
                                onChange={(e) => {
                                  const [currencyCC, currencyShort] = e.target.value.split('|');
                                  setCurrencyHandler(currencyCC);
                                  currency2Handler(currencyShort);
                                }}
                              >
                                <option value="0|">{ctx.selectCurrencyText}</option>
                                {ctx.liveCurrencyData.map((item, index) => (
                                  <option value={`${item.cc}|${item.currencyShort}`} key={index}>
                                    {item.currencyShort}
                                  </option>
                                ))}
                              </select>

                    <select className="bsLivePaymentSelectCurrencySign"
                      onChange={(e) => setCountryHandler(e.target.value)}
                    >
                      <option value="0">{ctx.selectLanguageText}</option>
                      {ctx.liveCountryData.map((item, index) => (
                        <option value={item.bslivelanguageid} key={index}>{item.benaemn}</option>
                      ))}
                    </select>
             </div>
           
       <div className="bsLivePaymentMessageSign">
            {/* {ctx.serviceInfo.map((item,index)=>(
              <div key={index} className="bsLivePaymentRadio">
                <input
                  type="radio"
                  value={item.serviceName}
                  checked={ctx.getSelectedService === item.serviceName}
                  onChange={() => setSelectedOptionHandler(item.serviceName)}
                />&nbsp;{item.serviceText}
              </div>
            ))} */}
            
                    {/* <div className="bsLivePaymentMessage">
                              <div className="bsLivePaymentRadio">
                                <input type="radio"
                                  value={'s2'}
                                  checked={ctx.getSelectedService === 's2'}
                                  onChange={() => setSelectedOptionHandler('s2')}
                                />&nbsp;{ctx.serviceS2Text}
                              </div>
                          </div>

                          <div className="bsLivePaymentMessage">
                              <div className="bsLivePaymentRadio">
                                <input type="radio"
                                  value={'s3'}
                                  checked={ctx.getSelectedService === 's3'}
                                  onChange={() => setSelectedOptionHandler('s3')}
                                />&nbsp;{ctx.serviceS3Text}
                              </div>
                          </div>

                          <div className="bsLivePaymentMessage">
                              <div className="bsLivePaymentRadio">
                                <input type="radio"
                                  value={'s4'}
                                  checked={ctx.getSelectedService === 's4'}
                                  onChange={() => setSelectedOptionHandler('s4')}
                                />&nbsp;{ctx.serviceS4Text}
                              </div>
                          </div> */}

 



                <div className="bsLiveSignupDefaultWrapper2">
                <div className="bsLiveSignupDefaultRowSign">
                  <MdDoneOutline className="bsLiveClearRecipeIcon" />&nbsp;
                  {ctx.buyOneCreditText} {getPrice||''} {currency||''}
                </div>
          </div>
          
    </div>
    

      <div className="bsLiveLoginButtonChoiceArea">
                    <button className="bsLivePaymentButton"
          onClick={()=>{checkEmailAddressHandler(ctx.email);setSelectedOptionHandler('s7')}}
          disabled={ctx.getSelectedService==='s1'&&ctx.email===''||!ctx.getSelectedService&&ctx.name===''||ctx.getSelectedService==='s1'&&ctx.password===''||ctx.getSelectedService==='s1'&&ctx.getConfirmPassword===''||ctx.getSelectedService==='s1'&&ctx.currency===''||ctx.getSelectedService==='s1'&&ctx.country===''||
            ctx.getSelectedService==='s1'&&ctx.password!==ctx.getConfirmPassword||priceInLocalCurrency===0}
          >{ctx.gotoPaymentText}</button>
          </div>
    

            
    </div>
  
  
   

        {ctx.stripePayOn&&(
          <div className="bsLivePaymentTopWrapper">
            <StripePayment/>  
          </div>
        )}
        
        </div>
        </div>

  )
}






function TabLogout(){
  let ctx=useContext(MyContext)

  (
    <div classname="bsLiveTabLogoutWrapper">
      <div className="bsLiveTabLogoutButton">{ctx.logOutText}</div>
    </div>
  )
}

function LoginUserOnly(){
  let ctx = useContext(MyContext);

  const emailHandler = (email) => {
    ctx.setEmail(email)
  }

  const passwordHandler = (password) => {
    ctx.setPassword(password)
  }

  const LoginHandler = () => {
    ctx.LoginUser()
  }

  return(

    <div className="bsLiveTabOnlyContainer">
     
    <div className="bsliveOnlyTabs">
      <div
        className="bsLiveLoginOnlyActive">
        {ctx.loginText2}         <VscClose className="bsLiveCloseLoginOnlyIcon"
                onClick={() => ctx.closeLoginHandler(false)}
                />
      </div>
    </div>
 
    <div className="bsLiveLoginOnlyTabContent">
  <div className="bsLiveUserLoginWrapper">
    <div   className="bsLiveLoginFormOnly"
      onSubmit={(e) => {
        e.preventDefault(); // Prevent default to control form handling in JavaScript
        LoginHandler(); // Call your login handler here
      }}
    >
      <input
        type="email"
        className="bsLiveUserLoginEmail"
        placeholder={ctx.placeHolderEmailText}
        value={ctx.email}
        name="email"
        autoComplete="email"
        onChange={(e) => emailHandler(e.target.value)}
      />
      
      <input
        type="password"
        className="bsLiveUserLoginPass"
        placeholder={ctx.placeHolderPasswordText}
        name="password"
        value={ctx.password}
        autoComplete="current-password"
        onChange={(e) => passwordHandler(e.target.value)}
      />
      
      <button type="submit" className="bsLiveLoginButtonUser"
       onClick={(e) => {
        e.preventDefault(); // Prevent default to control form handling in JavaScript
        LoginHandler(); // Call your login handler here
      }}>
        {ctx.loginText2}
      </button>
      <div className="bsLiveLoginForgotPasswordWrapper">
        <div className="bsLiveLoginForgotPasswordText"
        onClick={()=>ctx.forgotPasswordHandler()}
        >{ctx.forgotPasswordText}</div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}


function ForgotPasswordModal(){
  let ctx = useContext(MyContext);

  const emailHandler = (email) => {
    ctx.setEmail(email)
  }

  const LoginHandler = () => {
    ctx.LoginUser()
  }

  return(

    <div className="bsLiveTabOnlyContainer">
     
    <div className="bsliveOnlyTabs">
      <div
        className="bsLiveLoginOnlyActive">
        {ctx.resetPassText}<VscClose className="bsLiveCloseLoginOnlyIcon"
                onClick={()=>ctx.closeLoginHandler()}
                />
      </div>
    </div>
 
    <div className="bsLiveLoginOnlyTabContent1">
  <div className="bsLiveUserLoginWrapper">
    <div   className="bsLiveLoginFormOnly"
      onSubmit={(e) => {
        e.preventDefault(); // Prevent default to control form handling in JavaScript
        LoginHandler(); // Call your login handler here
      }}
    >
      <input
        type="email"
        className="bsLiveUserLoginEmail"
        placeholder={ctx.placeHolderEmailText}
        value={ctx.email}
        name="email"
        autoComplete="email"
        onChange={(e) => emailHandler(e.target.value)}
      />
      
      
      <button type="submit" className="bsLiveLoginButtonUser"
       onClick={(e) => {
        e.preventDefault(); // Prevent default to control form handling in JavaScript
       ctx.UpdatePasswordHandler(); // Call your login handler here
      }}>
        {ctx.resetPassText}
      </button>

    </div>
  </div>
</div>

    </div>
  )
}






function UserAccount() {
  const ctx = useContext(MyContext);

  // Retrieving and formatting recipes from localStorage
  
  let newRecipes = JSON.parse(localStorage.getItem("UnlockedRecipes"));
  newRecipes =
    newRecipes &&
    newRecipes.map((item) => {
      const dateStr = item.endDate;
      const formattedDate = dateStr.replace("T", " ").replace("Z", "").split(".")[0];
      const endDate = new Date(formattedDate);
      const currentDate = new Date();

      // Check if the end date has passed
      const dateEnded = endDate < currentDate;

      return {
        benaemn: item.benaemn,
        endDate: formattedDate,
        dateEnded: dateEnded, // true if the date has ended
      };
    });
 
    const [recipePairs, setRecipePairs] = useState([]);
    function getRecipePairs() {
      const recipes = [];
       
      // Loop through all keys in local storage
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
 
        // Check if the key starts with `recipeid:`
        if (key.startsWith('recipeid:')) {
          const recipeId = key.replace('recipeid:', ''); // Extract the ID
          let recipeName = JSON.parse(localStorage.getItem(`recipeHeader:${recipeId}`)); // Construct the matching `benaemn` key
          console.log(recipeName)
          // Check if the matching `benaemn` key exists
          if (recipeName) {
            recipes.push({
              id: recipeId,     // Recipe ID
              name: recipeName[0].benaemn  // Recipe name
            });
          }
        }
      }
    
      return recipes;
    }

    useEffect(() => {
      setRecipePairs(getRecipePairs());
    }, []); //

    const deleteSavedRecipeHandler=(id)=>{
      if(localStorage.getItem('LoginLiveToken')){
            localStorage.removeItem(`recipeid:${id}`);
            localStorage.removeItem(`benaemn:${id}`);
            setRecipePairs(getRecipePairs());
      }
    }
    
  return (
    <div className="bsLiveUserAccountWrapper">
      <div className="bsLiveTabUserContainer">
        {/* Tabs Navigation */}
        <div className="bsliveUserTabs">
          {[
            { id: 1, label: ctx.unlockedRecipesText },
            { id: 2, label: ctx.accountDetailsText },
            { id: 3, label: ctx.receiptText },
            { id: 4, label: ctx.savedRecipesText },
          ].map((tab) => (
            <div
              key={tab.id}
              className={`bsliveUserTab ${
                ctx.isCreateLogin === tab.id ? "bsliveUserActive" : ""
              }`}
              onClick={() => ctx.setIsCreateLogin(tab.id)}
            >
              {tab.label}
            </div>
          ))}
        </div>

        {/* Tabs Content */}
        <div className="bsLiveUserTabContent">
          {/* Recipes Tab */}
          {ctx.isCreateLogin === 1 && (
            <div className="bsLiveUserAccountUnlocked">
              <div className="bsLiveUserAccountUnlockedList">
                {newRecipes && newRecipes.length > 0 ? (
                  newRecipes.map((item, index) => (
                    <div className="bsLiveUserAccountUnlockedRow" key={index}>
                      <div className="bsLiveUserAccountUnlockedBenaemn">
                        {item.benaemn}
                        <div
                          className={`bsLiveAccountUnlockedText ${
                            item.dateEnded ? "bsLiveUnlockedEnded" : ""
                          }`}
                        >
                          <CalendarDays
                            className="bsLiveCalendarIcon"
                            title={ctx.endDateText}
                          />
                          {String(item.endDate).substring(0, 10)}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="bsLiveUserNoData">
                {ctx.norecipeAvailableText}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Account Tab */}
          {ctx.isCreateLogin === 2 && (
            <div className="bsLiveUserAccountInfo">
              <div className="bsLiveUserAccountInfoText">
              {ctx.nameText} {localStorage.getItem("LoginLiveName") || "Not available"}
              </div>
              <div className="bsLiveUserAccountInfoText">
                {ctx.countryText} {localStorage.getItem("LoginLiveCountry") || "Not available"}
              </div>
              <div className="bsLiveUserAccountInfoText">
                {ctx.currencyText} {localStorage.getItem("LoginLiveCurrency") || "Not available"}
              </div>
              <div className="bsLiveUserAccountInfoText">
                {ctx.creditsText}: {localStorage.getItem("LoginLiveCredits") || "Not available"}
              </div>
              <div className="bsLiveUserAccountChangeLangWrapper">
                <div className="bsLiveUserAccountChangeLangHeader">
                {ctx.updateLanguageHeader}
                </div>
                  <select className="bsLiveUserAccountChangeLangSelect"
                    onChange={(e) => ctx.changeToANewLanguageCodeHandler(e.target.value)}
                    >
                    <option value="0">{ctx.selectLanguageText}</option>
                    {ctx.languageCountries.map((item, index) => (
                      <option value={item.bslivelanguageid} key={index}>
                        {item.benaemn}
                      </option>
                    ))}
                  </select>
                <div   className={`bsLiveUserAccountChangeLangSubmitButton ${
                                            ctx.updateNewLanguageCodeToUser ? "" : "disabled"}`}
                disabled={!ctx.updateNewLanguageCodeToUser}
                onClick={()=>ctx.UpdateUserLanguageCode()}
                >{ctx.submitButtonChangeLanguageText}</div>
                  </div>

                  {/* <div className="bsLiveUserAccountBSignupWrapper">
                  <button className="bsLiveSignupButton"
            
            onClick={()=>ctx.joinBakesoftHandler()}>
            {ctx.joinBakesoftNowText}
            </button>


                </div> */}
    </div>
      
          )}
         

          {/* Receipt Tab */}
          {ctx.isCreateLogin === 3 && (
            <div className="bsLiveUserReceipt">
              {ctx.userTransactionData && ctx.userTransactionData.length > 0 ? (
                ctx.userTransactionData.map((item, index) => (
                  <div className="bsLiveUserReceiptRow" key={index}>
                    <div className="bsLiveUserReceipeText">
                      {String(item.createDate).substring(0, 10)} - {item.customerPrice}
                      &nbsp;{item.currencyName}
                      <a
                        href={item.recipeUrl}
                        className="bsLiveRecipeLink"
                        title="View Receipt"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LuFileText className="bsLiveReceiptIcon" />
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <div className="bsLiveUserNoData">{ctx.noTransactionsFoundText}</div>
              )}
            </div>
          )}
          {ctx.isCreateLogin === 4 && (
            <div className="bsLiveUserSavedRecipesWrapper">
              {recipePairs.map((item, index) => (
                <div className="bsLiveUserSavedRecipeRow" key={index}>
                   <div className="bsLiveUserLocallySavedRecipeTextId">{item.id}</div>
                  <div className="bsLiveUserLocallySavedRecipeText">{item.name}</div>
                  <div className="bsLiveUserLocallySavedDeleteButton"
                  onClick={()=>deleteSavedRecipeHandler(item.id)}
                  >{ctx.deleteSavedRecipeText}</div>
                </div>
              ))}
            </div>
          )}
      
        </div>
      </div>
    </div>
  );
}

function ShareONSocialMedia() {
  let ctx = useContext(MyContext);
  let { recipeid, userid } = ctx.socialSharedData;
  let predesignUrl = ctx.getPresignedImageUrl;


  const [showShareOptions, setShowShareOptions] = useState(false);
  const [shareType, setShareType] = useState('');
  const [copied, setCopied] = useState(false);

  // const toggleShareOptions = () => {
  //   setShowShareOptions(!showShareOptions);
  // };
  const ShareTypeHandler = (id) => {
    setShareType(id);
  };

  let facebookShareLink = '';
  let twitterShareLink = '';
  let instagramShareLink = '';
  let linkedInShareLink = '';
  let whatsappShareLink = '';
  let pinterestShareLink = '';
  let tweetText = '';
  let backendUrl = '';
  let linkWithNoNetWork = `https://live.bakesoft.se?receptid=${recipeid}&userid=${userid}`;

  if (shareType == 1) {
    // Facebook share link
    const backendUrl = `https://data.bakesoft.se/sharedData/${recipeid}/${userid}`;
    const shareMessage = "Check out this amazing recipe on Bakesoft Live! Don't miss it!";
    facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      backendUrl
    )}&quote=${encodeURIComponent(shareMessage)}`;
    

  } else if (shareType == 2) {
    // Twitter share link
    backendUrl = `https://data.bakesoft.se/sharedData/${recipeid}/${userid}`;
    // Tweet text only includes the backend URL
    tweetText = `Check out this recipe! ${backendUrl}`;
    twitterShareLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
  }else if (shareType == 3) {
    // Instagram share link
    const backendUrl = `https://data.bakesoft.se/sharedData/${recipeid}/${userid}`;
const shareMessage = "Check out this amazing recipe on Bakesoft Live! Don't miss it!";
const imageUrl = `${predesignUrl}`; // Replace with your recipe image URL
// Instagram Stories deep link
instagramShareLink = `https://www.instagram.com/create/story/?media=${imageUrl}`;
  }else if(shareType==4){
    const backendUrl = `https://data.bakesoft.se/sharedData/${recipeid}/${userid}`;
    linkedInShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(backendUrl)}`;
console.log(linkedInShareLink);
  }else if(shareType==5){
const backendUrl = `https://live.bakesoft.se?receptid=${recipeid}&userid=${userid}`;
const whatsappMessage = `Check out this amazing recipe on Bakesoft Live: ${backendUrl}`;
whatsappShareLink = `https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`;

  }else if(shareType==6){
  const backendUrl = `https://data.bakesoft.se/sharedData/${recipeid}/${userid}`; // The recipe's URL
const shareMessage = "Check out this amazing recipe on Bakesoft Live! Don't miss it!"; // Description for the pin
const imageUrl = `${predesignUrl}`; // Replace with your recipe image URL

// Pinterest Share Link
pinterestShareLink = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(backendUrl)}&media=${encodeURIComponent(imageUrl)}&description=${encodeURIComponent(shareMessage)}`;
  }



  const handleCopyLink = () => {
      navigator.clipboard.writeText(linkWithNoNetWork)
          .then(() => {
              setCopied(true);
              setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
            //  if (onShare) onShare(); // Optional callback when the link is shared
          })
          .catch((err) => console.error('Failed to copy link:', err));
  };


  return (
    <div className="socialArea">
      <div className="shareOptions">
        <IoIosCloseCircleOutline
          onClick={() => ctx.setShowShareModal(false)}
          className="bsLivecloseShareIcon"
          
        />

        <a
          href={facebookShareLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => ShareTypeHandler(1)}
          title={ctx.facebookText}
        >
          <FaFacebook />
        </a>

        <a
          href={`${twitterShareLink}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => ShareTypeHandler(2)}
          title={ctx.twitterText}
        >
              <svg
        xmlns="http://www.w3.org/2000/svg"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        imageRendering="optimizeQuality"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 512 462.799"
        width="25px" // Set the desired width
        height="25px" // Set the desired height

      >
        <path
          fillRule="nonzero"
          d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"
        />
      </svg>
        </a>
        <a
          href={`${instagramShareLink}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => ShareTypeHandler(3)}
          title={ctx.instagramText}
        >
          <FaInstagram />
        </a>
        {/* <a
          href={`${linkedInShareLink}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => ShareTypeHandler(4)}
        >
          <FaLinkedin />
        </a> */}
        {/* <a
          href={`${whatsappShareLink}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => ShareTypeHandler(5)}
        >
          <FaWhatsapp />
        </a> */}

         <a
          href={`${pinterestShareLink}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => ShareTypeHandler(6)}
          title={ctx.pinterestText}
        >
          <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24.48"
        height="24.48"
        fill="black"
      >
        <path d="M12 0C5.373 0 0 5.373 0 12c0 4.991 3.053 9.293 7.425 11.052-.103-.942-.196-2.385.041-3.414.213-.922 1.374-5.875 1.374-5.875s-.35-.7-.35-1.733c0-1.623.94-2.836 2.108-2.836.993 0 1.47.746 1.47 1.64 0 1-.636 2.493-.965 3.875-.274 1.165.582 2.115 1.723 2.115 2.067 0 3.462-2.712 3.462-5.916 0-2.44-1.645-4.27-4.636-4.27-3.378 0-5.497 2.528-5.497 5.348 0 .966.283 1.643.726 2.17.207.24.236.336.161.612-.056.201-.178.688-.228.882-.073.288-.297.391-.548.284-1.534-.633-2.233-2.33-2.233-4.256 0-3.159 2.673-6.935 8.002-6.935 4.279 0 7.108 3.1 7.108 6.42 0 4.414-2.436 7.705-6.056 7.705-1.213 0-2.352-.659-2.739-1.396l-.743 2.833c-.268 1.04-.995 2.341-1.488 3.134 1.117.346 2.295.537 3.522.537 6.627 0 12-5.373 12-12S18.627 0 12 0z" />
      </svg>
        </a>
        <div className="share-link">
            <a
              //  href={linkWithNoNetWork}
                target="_blank"
                rel="noopener noreferrer"
                className="pinterest-share"
                onClick={() => handleCopyLink()}
                title={ctx.copyLinkText}
            >
                <LuCopy className="share-icon" size={24} />
            </a>
            {copied && <span className="socialAreaShareLink">{ctx.linkCopiedText}</span>}
        </div>

        {/* Add more icons as needed */}
      </div>
    </div>
  );
}


function AccountSetupCompletePage() {
  let ctx = useContext(MyContext)



return (
  <div className="bsLiveStripeAccountSetupWrappper">
    <PaymentSuccess/>
  </div>
)
}

function JoinBakesoft(){
let ctx=useContext(MyContext)



  return(
      <div className="bakesoft-join">

        <div className="bakesoft-join__card">
          <div className="bakesoftJoinCloseIconWrapper">
        <VscClose className="bakesoftJoinCloseIcon"
          onClick={()=>{ctx.setCloseJoinBakesoftModal(true);ctx.setShowJoinBakesoftAdd(false)}}
          />
        </div>
          <header className="bakesoft-join__header">      
            <h2 className="bakesoft-join__title">
             {ctx.JoinBakesoftText}
            </h2>
          </header>
          <div className="bakesoft-join__content">
            <p className="bakesoft-join__text">
              {/* {ctx.createAccountTodayText} */}
              <br></br>
              <span className="bakesoft-join__text--bold">{ctx.bakesoftSignUp1}</span>
              <br></br> {ctx.bakesoftSignUp2}
              <br></br><br></br>

              <span className="bakesoft-join__text--bold">{ctx.bakesoftSignUp3}</span> 
              <br></br>
                      {ctx.bakesoftSignUp4}
                      {ctx.bakesoftSignUp5}
  
       
              

            </p>
            <p className="bakesoft-join__text">
            <span className="bakesoft-join__text--bold">   {ctx.plattformOfferingText}</span>
            </p>
            <div className="bakesoft-join__highlight">
              <p className="bakesoft-join__highlight-text">
               {ctx.signUpOfferingText}
              </p>
            </div>
          </div>
          {/* <footer className="bakesoft-join__footer">
            <div className="bakesoft-join__ButtonWrapper">
            <button className="bakesoft-join__button"
            
            onClick={()=>ctx.joinBakesoftHandler()}>
            {ctx.joinBakesoftNowText}
            </button>
          </div>

            {ctx.bakesoftSetup&&(
            <div className="bakesoft-join__setupInfoWrapper">
              <div className="bakesoft-join__setupInfo">{ctx.accountDoneSetupText}</div>
              <div className="bakesoft-join__setupInfo">{ctx.loginemailandpassText}</div>
              <div className="bakesoft-join__setupInfo">{ctx.thankYouForJoiningUsText}</div>
              </div>
            )}
          </footer> */}
          </div>

          <div className="bakesoft-join__note">
           {ctx.topublishRecipesOnLiveText}
          </div>
        </div>

    );

}


const SnowfallEffect = () => {
  useEffect(() => {
    const snowContainer = document.getElementById("snow-container");

    // Create snowflakes dynamically
    const createSnowflake = () => {
      const snowflake = document.createElement("div");
      snowflake.className = "bsLivesnowflake";
      snowflake.style.left = `${Math.random() * 100}vw`;
      snowflake.style.animationDuration = `${Math.random() * 3 + 2}s`;
      snowflake.style.opacity = `${Math.random()}`;
      snowContainer.appendChild(snowflake);

      // Remove snowflake after it falls
      setTimeout(() => {
        snowContainer.removeChild(snowflake);
      }, 5000);
    };

    // Generate snowflakes at regular intervals
    const interval = setInterval(createSnowflake, 100);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return <div id="snow-container" className="bsLivesnowfall"></div>;
};

const SlideMenu = () => {
  let ctx = useContext(MyContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div  className="bsLiveMenuTop">
      {/* Hamburger Button */}
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>

      {/* Slide-In Menu */}
      <div className={`slide-menu ${isOpen ? "open" : ""}`}>
        {/* <button className="close-btn" onClick={toggleMenu}>
          ✖
        </button> */}
        <div className="bsLiveMenuWrapper">
          <div className="bsLiveMenuItems">
            {/* <div className="bsLiveMenuItem">{ctx.menyText}</div> */}
            <div className="bsLiveMenuItem"
            onClick={()=>{ctx.menuHandler(1);closeMenu()}}>
              <Key className="bsLIveMenuIcon"/>{ctx.loginText2}</div>

              {/* <div className="bsLiveMenuItem"
            onClick={()=>{ctx.menuHandler(9);closeMenu()}}>
              <Key className="bsLIveMenuIcon"/>Ny signup</div> */}

            <div className="bsLiveMenuItem"
            onClick={()=>{ctx.menuHandler(2);closeMenu()}}>
            <DoorOpen className="bsLIveMenuIcon"/>{ctx.logOutText}</div>

            <div className="bsLiveMenuItem"
            onClick={()=>{ctx.menuHandler(3);closeMenu()}}>
            <UserCircle className="bsLIveMenuIcon"/>{ctx.myAccountText}</div>

            <div className="bsLiveMenuItem"
            onClick={()=>{ctx.menuHandler(4);closeMenu()}}>
            <Book className="bsLIveMenuIcon"/>{ctx.myBookmarkedRecipesText}</div>

            <div className="bsLiveMenuItem"
            onClick={()=>{ctx.menuHandler(5);closeMenu()}}>
            <UserCheck className="bsLIveMenuIcon"/>{ctx.SignUpToBakesoftText}</div>

            <div className="bsLiveMenuItem"
            onClick={()=>{ctx.menuHandler(6);closeMenu()}}>
            <LifeBuoy className="bsLIveMenuIcon"/>{ctx.howDoesItWorkText}</div>

           

            <div className="bsLiveMenuItem"
            onClick={()=>{ctx.menuHandler(7);closeMenu()}}>
            <Scale className="bsLIveMenuIcon"/>{ctx.termsAndConditionsText}</div>

            <div className="bsLiveMenuItem"
            onClick={()=>{ctx.menuHandler(9);closeMenu()}}>
            <Contact2Icon    className="bsLIveMenuIcon"/>{ctx. aboutUsLiveText}</div>



            {localStorage.getItem('LoginLiveToken')&&(
            <div className="bsLiveMenuItem"
            onClick={()=>{ctx.menuHandler(8);closeMenu()}}>
            <AlarmClock className="bsLIveMenuIcon"/>{ctx.kitchenTimerText}</div>
            
            
            )}
        </div>
      
        
        </div>

      </div>

      {/* Backdrop (only visible when menu is open) */}
      {isOpen && <div className="backdrop" onClick={toggleMenu}></div>}
    </div>
  );
};

function KTimer(props){
  let {kitchenTimerHandler}=props

return(
  <div className="bsLiveTimerWrapper">
    <CookingTimer kitchenTimerHandler={kitchenTimerHandler}/>

  </div>
)

}





export{ShowLiveRecipe,PaymentHandler,StripePayment,UserPresentation,CompletePage,AccountSetupCompletePage,JoinBakesoft,
  CheckoutForm,TabLogin,TabLogout,LoginUserOnly,LogoutUserOnly,UserAccount,ShareONSocialMedia,StripePaymentInside,SnowfallEffect,
  Signup,SlideMenu,KTimer,ForgotPasswordModal}



  // const recipeLink = `https://live.bakesoft.se/bslrid/${recipeid}`;
// const preSignedImageUrl = predesignUrl;
// const encodedRecipeLink = encodeURIComponent(recipeLink);
// const encodedImageUrl = encodeURIComponent(preSignedImageUrl);
// const tweetText = `Check out this recipe! ${encodedRecipeLink}\nImage: ${encodedImageUrl}`;
// const twitterShareLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;


// const backendUrl = `https://bakesoftbackend-5il3q.ondigitalocean.app/sharedData/${recipeid}/${userid}`;
// const preSignedImageUrl = predesignUrl;

// // Encode the URLs and create the tweet text with a line break
// const tweetText = `Check out this recipe! ${backendUrl}\nImage: ${preSignedImageUrl}`;
// const twitterShareLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
// console.log(twitterShareLink)


{/* <button className="bsLiveActionAreaButton"
                                onClick={ctx.PaymentModalHandler}><CiUnlock className="bsLiveButtonIcon"/>&nbsp;
                              Unlock &nbsp;<BiHappyBeaming className="bsLiveButtonIcon"/>
                    </button> */}




                         {/* <select className="bsLiveAddIngredientSelect"
                  onChange={(e) => ctx.addUserIngredientHandler(e.target.value)}
                >
                  {ctx.creatorIngredients.map((item, index) => (
                    <option value={item.ingredid} key={index}>{item.benaemn}</option>
                  ))}
                </select> */}

          
